import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Card } from 'react-bootstrap';
import AddFoodForm from './AddFoodForm'; // Adjust the path as needed
import EditFoodForm from './EditFoodForm'; // Adjust the path as needed
import api from '../../api';
import Paginate from '../../util/Paginate';
function Food() {
  const [foods, setFoods] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFood, setSelectedFood] = useState(null);
  const [foodCategories, setFoodCategories] = useState([]); // State for food categories

  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // ...

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = foods.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
 };

  useEffect(() => {
    // Fetch food data when the component mounts
    api.get('/food')
      .then((response) => {
        setFoods(response.data);
      })
      .catch((error) => {
        console.error('Error fetching foods:', error);
      });

      api.get('/food/food-categories')
          .then((response) => {
        setFoodCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching food categories:', error);
      });
  }, []);

  


  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleShowEditModal = (food) => {
    setSelectedFood(food);
    setShowEditModal(true);
  };

  const handleShowDeleteModal = (food) => {
    setSelectedFood(food);
    setShowDeleteModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedFood(null);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedFood(null);
  };

  const handleAddFood = (newFood) => {
    // Send POST request to add food
    api.post('/food', newFood)
      .then((response) => {
        setFoods([...foods, response.data]);
        handleCloseAddModal();
      })
      .catch((error) => {
        console.error('Error adding food:', error);
      });
  };

  const handleEditFood = (editedFood) => {
    // Send PUT request to update food

    api
      .put(`/food/${selectedFood._id}`, editedFood)
      .then((response) => {
        const updatedFood = response.data;
        const updatedFoods = foods.map((food) => {
          if (food._id === selectedFood._id) {
            return updatedFood; // Use the updated food data from the response
          }
          return food;
        });
        setFoods(updatedFoods);
        handleCloseEditModal();

      })
      .catch((error) => {
        console.error('Error editing food:', error);
      });
  };

  const handleDeleteFood = () => {
    // Send DELETE request to delete food
    api.delete(`/food/${selectedFood._id}`)
      .then(() => {
        const updatedFoods = foods.filter((food) => food._id !== selectedFood._id);
        setFoods(updatedFoods);
        handleCloseDeleteModal();
      })
      .catch((error) => {
        console.error('Error deleting food:', error);
      });
  };

  return (
    <div className="container mt-4"> 
                <Card className="mb-4">
            <Card.Header> <h1>Food List</h1></Card.Header>
            <Card.Body>
      <Button variant="primary" onClick={handleShowAddModal}>Add Food</Button>
      <Table striped bordered hover>
        <thead>
          <tr>
          
            <th>Name</th>
            <th>Calories</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((food, index) => (
            <tr key={food._id}>
                          <td>{index}</td>

              <td>{food.name.en} - {food.name.ar}</td>
              <td>{food.calories}</td>
              <td>{food.foodcategory}</td>

              <td>
                <Button variant="primary" onClick={() => handleShowEditModal(food)} className='m-2'>Edit</Button>
                <Button variant="danger" onClick={() => handleShowDeleteModal(food)} className='m-2'>Delete</Button>
              </td>
            </tr>
          ))}
                      
        </tbody>
        
      </Table>
      <Paginate
                  itemsPerPage={itemsPerPage}
                  totalItems={foods.length}
                  currentPage={currentPage}
                  paginate={paginate}
               />
      {/* Add Food Modal */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Food</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddFoodForm  foodCategories={foodCategories}  onSubmit={handleAddFood} />
        </Modal.Body>
      </Modal>

      {/* Edit Food Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Food</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditFoodForm foodCategories={foodCategories}   food={selectedFood} onSubmit={handleEditFood} />
        </Modal.Body>
      </Modal>

      {/* Delete Food Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Food</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this food item?</p>
          <Button variant="danger" onClick={handleDeleteFood}>Delete</Button>
        </Modal.Body>
      </Modal>
      
      </Card.Body>
      </Card>
    </div>
  );
}

// AddFoodForm and EditFoodForm components can be defined similarly to the previous examples.

export default Food;
