import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Card } from 'react-bootstrap';
import AddPostForm from './AddPostForm'; // Adjust the path as needed
import EditPostForm from './EditPostForm'; // Adjust the path as needed
import api from '../../api';
import Paginate from '../../util/Paginate';
function Posts() {
  const [foods, setFosts] = useState([]);
  const [posts, setPosts] = useState([]);
  const [deletedPost, setDeletedPost] = useState({});

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [foodCategories, setFoodCategories] = useState([]); // State for post categories
  const [postCategories, setPostCategories] = useState([]); // State for post categories

  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // ...

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = posts.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
 };

  useEffect(() => {
    // Fetch post data when the component mounts
    api.get('/posts')
      .then((response) => {
        setPosts(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
      });

      api.get('/categories')
          .then((response) => {
            setPostCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching posts categories:', error);
      });
  }, []);

  


  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleShowEditModal = (post) => {
    setSelectedPost(post);
    setShowEditModal(true);
  };

  const handleShowDeleteModal = (post) => {
    setSelectedPost(post);
    setShowDeleteModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedPost(null);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedPost(null);
  };

  const handleAddPost = (newPost) => {
    // Send POST request to add post
    console.log(newPost)
    api.post('/posts', newPost)
      .then((response) => {
        setPosts([...posts, response.data]);
        handleCloseAddModal();
      })
      .catch((error) => {
        console.error('Error adding Post:', error);
      });
  };

  const handleEditPost = (editedPost) => {
    // Send PUT request to update post
console.log(editedPost)  
    api
      .put(`/posts/edit/${selectedPost._id}`, editedPost)
    .then((response) => {
        const updatedPost = response.data;
        const updatedPosts = posts.map((post) => {
          if (post._id === selectedPost._id) {
            return updatedPost; // Use the updated post data from the response
          }
          return post;
        });
        setPosts(updatedPosts);
        handleCloseEditModal();

      })
      .catch((error) => {
        console.error('Error editing post:', error);
      });
  };

  const handleDeletePost = () => {
    // Send DELETE request to delete post
    api.delete(`/posts/${selectedPost._id}`)
      .then(() => {
        const updatedPosts = posts.filter((post) => post._id !== selectedPost._id);
        setPosts(updatedPosts);
        handleCloseDeleteModal();
      })
      .catch((error) => {
        console.error('Error deleting post:', error);
      });
  };

  return (
    <div className="container-fluid mt-4"> 
                <Card className="mb-4">
            <Card.Header> <h1>Post List</h1></Card.Header>
            <Card.Body>
      <Button variant="primary" onClick={handleShowAddModal}>Add Post</Button>
      <Table striped bordered hover>
        <thead>
          <tr>
          
            <th>#</th>
            <th>Title</th>
            <th>short_desc</th>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
          {currentItems.map((post, index) => (
            <tr key={post._id}>
                          <td>{index}</td>

              <td>{post.title.en} - {post.title.ar}</td>
              <td>{post.short_desc.en} - {post.short_desc.ar}</td>

              <td>
                <Button variant="primary" onClick={() => handleShowEditModal(post)} className='m-2'>Edit</Button>
                <Button variant="danger" onClick={() => handleShowDeleteModal(post)} className='m-2'>Delete</Button>
              </td>
            </tr>
          ))}
                
        </tbody>
        
      </Table>
      <Paginate
                  itemsPerPage={itemsPerPage}
                  totalItems={posts.length}
                  currentPage={currentPage}
                  paginate={paginate}
               />
      {/* Add Post Modal */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>
          <AddPostForm  postCategories={postCategories}  onSubmit={handleAddPost} />
        </Modal.Body>
      </Modal>

      {/* Edit Post Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
          <EditPostForm postCategories={postCategories}   post={selectedPost} onSubmit={handleEditPost} />
        </Modal.Body>
      </Modal>

      {/* Delete Post Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this post item {deletedPost.arName}? </p>
          <Button variant="danger" onClick={handleDeletePost}>Delete</Button>
        </Modal.Body>
      </Modal>
      
      </Card.Body>
      </Card>
    </div>
  );
}

// AddFoodForm and EditFoodForm components can be defined similarly to the previous examples.

export default Posts;
