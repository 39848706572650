import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const AddProductForm = ({ productCategories, onSubmit }) => {
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    categoryId: '', // Assuming categoryId is the property for the category
  });

  const handleChange = (e) => {
    setNewProduct({
      ...newProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(newProduct);
    // Optionally, you can reset the form fields here
    setNewProduct({
      name: '',
      description: '',
      categoryId: '',
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="productName">
        <Form.Label>Product Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter product name"
          name="name"
          value={newProduct.name}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="productDescription">
        <Form.Label>Product Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter product description"
          name="description"
          value={newProduct.description}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="productCategory">
        <Form.Label>Product Category</Form.Label>
        <Form.Control
          as="select"
          name="categoryId"
          value={newProduct.categoryId}
          onChange={handleChange}
        >
          <option value="">Select category</option>
          {productCategories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Button variant="primary" type="submit">
        Add Product
      </Button>
    </Form>
  );
};

export default AddProductForm;
