// EditProductPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, Container, Card } from 'react-bootstrap';
import api from '../../api';
import { useAuth } from '../../util/AuthContext';
import Image from 'react-bootstrap/Image'; // Add this line
import ImgUrl from '../../util/ImgUrl';
import LoadingPage from '../../util/Loading';


const EditProductPage = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const { productId } = useParams();
    const { accessToken } = useAuth();
    const [productCategories, setProductCategories] = useState([]);
    const publicImageUrl = 'http://192.168.80.11:5000/uploads/'; // Update this with the actual base URL for your public images
    const [product, setProduct] = useState({
        name: '',
        mainImage: '',
        carouselImages: [],
        description: '',
        price: 0,
        salePrice: 0,
        price_wholesale: 0,
        price_wholesale_plus: 0,
        variations: [],
        quantity: 0,
        category: '',
        brand: '',
        featured: false,
        offer: false,
        ratings: 0,
    });
    const [loading, setLoading] = useState(true); // New state for loading
    const navigation = useNavigate();




    useEffect(() => {

        const fetchCategories = async () => {
            try {
                const categoriesResponse = await api.get('/category/categories');
                setProductCategories(categoriesResponse.data);
                setLoading(false); // Set loading to false after data is fetched

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchProduct = async () => {
            try {
                const response = await api.get(`products/products/${productId}`, {
                    headers: {
                        token: `Bearer ${accessToken}`,
                    }
                },);

                const fetchedProduct = response.data;
                setLoading(false); // Set loading to false after data is fetched
                setProduct({
                    name: fetchedProduct.name || '',
                    mainImage: fetchedProduct.mainImage || '',
                    carouselImages: fetchedProduct.carouselImages || [],
                    description: fetchedProduct.description || '',
                    price: fetchedProduct.price || 0,
                    salePrice: fetchedProduct.salePrice || 0,
                    price_wholesale: fetchedProduct.price_wholesale || 0,
                    price_wholesale_plus: fetchedProduct.price_wholesale_plus || 0,
                    variations: fetchedProduct.variations || [],
                    quantity: fetchedProduct.quantity || 0,
                    category: fetchedProduct?.category || '',
                    brand: fetchedProduct?.brand?._id || '',
                    featured: fetchedProduct.featured || false,
                    offer: fetchedProduct.offer || false,
                    ratings: fetchedProduct.ratings || 0,
                });
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProduct();
        fetchCategories();
    }, [productId]);

    // const handleChange = (e) => {
    //     setProduct({
    //         ...product,
    //         [e.target.name]: e.target.value,
    //     });
    // };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
      
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };

    //   const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //       const formData = new FormData();
    //       // Append each field to formData
    //       Object.keys(product).forEach((key) => {
    //         if (Array.isArray(product[key])) {
    //           // Handle arrays (e.g., carouselImages)
    //           product[key].forEach((file, index) => {
    //             formData.append(`${key}[${index}]`, file);
    //           });
    //         } else {
    //           formData.append(key, product[key]);
    //         }
    //       });

    //       await api.put(`products/products/${productId}`, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
    //         },
    //       });

    //       // Handle success or navigate back to the product list
    //       history.push('/products');
    //     } catch (error) {
    //       console.error('Error editing product:', error);
    //     }
    //   };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const formData = new FormData();
    //         // Append each field to formData
    //         Object.keys(product).forEach((key) => {
    //             if (Array.isArray(product[key])) {
    //                 // Handle arrays (e.g., carouselImages)
    //                 product[key].forEach((file) => {
    //                     formData.append(key, file);
    //                 });
    //             } else {
    //                 formData.append(key, product[key]);
    //             }
    //         });

    //         await api.put(`products/products/${productId}`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
    //                 token: `Bearer ${accessToken}`,
    //             },
    //         });

    //         // Handle success or navigate back to the product list
    //         history.push('/products');
    //     } catch (error) {
    //         console.error('Error editing product:', error);
    //     }
    // };


    const handleSubmit = async (e) => {
        setLoading(true); // Set loading to false after data is fetched

        e.preventDefault();
        try {
            const formData = new FormData();
    
            // Append each field to formData
            Object.entries(product).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    // Handle arrays (e.g., carouselImages and variations)
                    value.forEach((item, index) => {
                        if (key === 'variations') {
                            // Append each variation's fields individually
                            Object.entries(item).forEach(([variationKey, variationValue]) => {
                                formData.append(`variations[${index}][${variationKey}]`, variationValue);
                            });
                        } else {
                            formData.append(`${key}[${index}]`, item);
                        }
                    });
                } else {
                    formData.append(key, value);
                }
            });
    
            await api.put(`products/products/${productId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: `Bearer ${accessToken}`,
                },
            });
            setLoading(false); // Set loading to false after data is fetched
            setSuccessMessage('Product updated successfully');

            // Handle success or navigate back to the product list
          //  navigation('/products');  // Use navigation function directly
        } catch (error) {
            console.error('Error editing product:', error);
        }
    };
    

    const handleImageChange = (e) => {
        setProduct({
            ...product,
            [e.target.name]: e.target.files[0], // Use files array for file input
        });
    };

    const handleCarouselImagesChange = (e) => {
        const files = Array.from(e.target.files);
        setProduct({
            ...product,
            carouselImages: files, // Use files array for multiple file input
        });
    };


    const handleVariationChange = (index, e) => {
        const { name, value } = e.target;

        setProduct((prevProduct) => {
            const newVariations = [...prevProduct.variations];
            newVariations[index] = {
                ...newVariations[index],
                [name]: value,
            };

            return {
                ...prevProduct,
                variations: newVariations,
            };
        });
    };

    const handleAddVariation = () => {
        setProduct((prevProduct) => ({
            ...prevProduct,
            variations: [
                ...prevProduct.variations,
                { name: '', price: 0, quantity: 0 },
            ],
        }));
    };

    const handleRemoveVariation = (index) => {
        setProduct((prevProduct) => {
            const newVariations = prevProduct.variations.filter((_, i) => i !== index);
    
            return {
                ...prevProduct,
                variations: newVariations.length > 0 ? newVariations : [],
            };
        });
    };
    
    


    if (loading) {
        return <LoadingPage />;
      }

    return (
        <Container className='py-5'>
        {successMessage && (
    <div className="alert alert-success mt-3" role="alert">
        {successMessage}
    </div>
)}
            <Card className="mb-4">
                <Card.Header>
                <h2>Edit Product</h2>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mt-2 mb-2" controlId="productName">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control className="mt-2 mb-2"
                                type="text"
                                placeholder="Enter product name"
                                name="name"
                                value={product.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        {/* 
        <Form.Group controlId="productCarouselImages">
          <Form.Label>Carousel Images URLs</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter comma-separated carousel image URLs"
            name="carouselImages"
            value={product.carouselImages.join(',')}
            onChange={handleChange}
            required
          />
        </Form.Group> */}

                        <Form.Group className="mt-2 mb-2" controlId="productDescription">
                            <Form.Label>Product Description</Form.Label>
                            <Form.Control className="mt-2 mb-2"
                                as="textarea"
                                placeholder="Enter product description"
                                name="description"
                                value={product.description}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group as={Row} controlId="priceRow">
                            <Form.Label className="mt-2 mb-2" column sm="3">
                                Product Price
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control className="mt-2 mb-2"
                                    type="number"
                                    placeholder="Enter product price"
                                    name="price"
                                    value={product.price}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>

                            <Form.Label className="mt-2 mb-2" column sm="3">
                                Sale Price
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control className="mt-2 mb-2"
                                    type="number"
                                    placeholder="Enter sale price"
                                    name="salePrice"
                                    value={product.salePrice}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="wholesaleRow">
                            <Form.Label className="mt-2 mb-2" column sm="3">
                                Wholesale Price
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control className="mt-2 mb-2"
                                    type="number"
                                    placeholder="Enter wholesale price"
                                    name="price_wholesale"
                                    value={product.price_wholesale}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>

                            <Form.Label className="mt-2 mb-2" column sm="3">
                                Wholesale Plus Price
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control className="mt-2 mb-2"
                                    type="number"
                                    placeholder="Enter wholesale plus price"
                                    name="price_wholesale_plus"
                                    value={product.price_wholesale_plus}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </Form.Group>

                        {/* Add form fields for other properties with required attribute */}
                        <Form.Group as={Row} controlId="quantityCategoryRow">
                            <Form.Label className="mt-2 mb-2" column sm="3">
                                Product Quantity
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control className="mt-2 mb-2"
                                    type="number"
                                    placeholder="Enter product quantity"
                                    name="quantity"
                                    value={product.quantity}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>

                            <Form.Label className="mt-2 mb-2" column sm="3">
                                Product Category
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control className="mt-2 mb-2"
                                    as="select"
                                    name="category"
                                    value={product.category}
                                    onChange={handleChange}
                                >
                                    <option value="">Select category</option>
                                    {productCategories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="productOffer" as={Row} className="mt-2 mb-2">
                            <Col sm="3">
                                <Form.Check
                                    type="checkbox"
                                    label="Offer"
                                    name="offer"
                                    checked={product.offer}
                                    onChange={handleChange}
                                /></Col>
                                
                            <Col sm="3">
                                <Form.Check
                                    type="checkbox"
                                    label="Featured"
                                    name="featured"
                                    checked={product.featured}
                                    onChange={handleChange}
                                /></Col>
                        </Form.Group>

                        <Form.Group className="mt-2 mb-2" controlId="productMainImage">
                            <Form.Label>Main Image</Form.Label>
                            <br />
                            {product.mainImage && (
                                <Image
                                    src={`${ImgUrl}${product.mainImage}`}
                                    alt="Main Image"
                                    thumbnail
                                    style={{ width: '100px', height: '100px' }}
                                />
                            )}
                            <Form.Control className="mt-2 mb-2"
                                type="file"
                                label="Choose main image"
                                name="mainImage"
                                onChange={handleImageChange}
                            />
                        </Form.Group>

                        <Form.Group className="mt-2 mb-2" controlId="productCarouselImages">
                            <Form.Label>Carousel Images</Form.Label>
                            <br />
                            {/* Conditional rendering for current carousel images */}
                            {product.carouselImages &&
                                product.carouselImages.map((image, index) => (
                                    <Image
                                        key={index}
                                        src={`${ImgUrl}${image}`}
                                        alt={`Carousel Image ${index}`}
                                        thumbnail
                                        style={{ width: '100px', height: '100px' }}
                                    />
                                ))}
                            <br />
                            <Form.Control className="mt-2 mb-2"
                                type="file"
                                label="Choose new carousel images"
                                name="carouselImages"
                                onChange={handleCarouselImagesChange}
                                multiple
                            />
                        </Form.Group>



   {/* Variation section */}
   {product.variations.map((variation, index) => (
                            <div key={index}>
                                <Form.Group as={Row} controlId={`variationName${index}`}>
                                    <Form.Label className="mt-2 mb-2" column sm="2">
                                        Variation Name
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control
                                            className="mt-2 mb-2"
                                            type="text"
                                            placeholder="Enter variation name"
                                            name="name"
                                            value={variation.name}
                                            onChange={(e) => handleVariationChange(index, e)}
                                            required
                                        />
                                    </Col>

                                    <Form.Label className="mt-2 mb-2" column sm="2">
                                        Variation Price
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control
                                            className="mt-2 mb-2"
                                            type="number"
                                            placeholder="Enter variation price"
                                            name="price"
                                            value={variation.price}
                                            onChange={(e) => handleVariationChange(index, e)}
                                            required
                                        />
                                    </Col>

                                    {/* Add other variation-specific fields as needed */}
                                    <Col sm="2">
                                    <Button
                                        variant="danger"
                                        className="mt-2 mb-2"
                                        onClick={() => handleRemoveVariation(index)}
                                    >
                                        Remove Variation
                                    </Button>
                                    </Col>
                                </Form.Group>
                            </div>
                        ))}

                        <Button
                            variant="success"
                            className="mt-2 mb-2"
                            onClick={handleAddVariation}
                        >
                            Add Variation
                        </Button>
<br/>

                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default EditProductPage;
