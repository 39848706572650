import React, { useState, useEffect } from 'react';
import api from '../../api'; // Import your API instance
import { useParams } from 'react-router-dom';
import AddCourseForm from './AddCourseForm';
import { Card, Table, Button, Form, Modal } from 'react-bootstrap';
import LoadingPage from '../LoadingPage';
function CourseManagementPage({ userId }) {
    const [subscription, setSubscription] = useState(null);
    const [newCourseName, setNewCourseName] = useState('');
    const [isAddingCourse, setIsAddingCourse] = useState(false);
    const [newReps, setNewReps] = useState(8);
    const [newSets, setNewSets] = useState(4);
    const [newSuperset, setNewSuperset] = useState(false);
    const [newDayNumber, setNewDayNumber] = useState('');
    const [isAddingDay, setIsAddingDay] = useState(false); // Define isAddingDay state
    const [newExercise, setNewExercise] = useState([]);
    const [isAddingExercise, setIsAddingExercise] = useState(null); // Define isAddingExercise state
    const { id } = useParams();
    const [selectedExercises, setSelectedExercises] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null); // Define selectedCategory state
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [exerciseToDeleteInfo, setExerciseToDeleteInfo] = useState(null);
    const [exercises, setExercises] = useState([]);
    const [exerciseToDelete, setExerciseToDelete] = useState([]);
    const handleDeleteExercise = (dayIndex, exerciseIndex, innerExerciseIndex) => {
        const updatedCourse = {
            ...subscription.courses[0],
            days: subscription.courses[0].days.map((day, index) => {
                if (index === dayIndex) {
                    return {
                        ...day,
                        exercises: day.exercises.map((exercise, exIndex) => {
                            if (exIndex === exerciseIndex) {
                                const updatedExercises = exercise.exercises.filter(
                                    (_, innerIndex) => innerIndex !== innerExerciseIndex
                                );
                                return {
                                    ...exercise,
                                    exercises: updatedExercises,
                                };
                            }
                            return exercise;
                        }),
                    };
                }
                return day;
            }),
        };

        api.put(`/course/${id}/courses`, { course: updatedCourse })
            .then(response => {
                setSubscription(response.data);
            })
            .catch(error => {
                console.error('Error deleting exercise:', error);
            });
    };


    const handleShowDeleteConfirmation = (dayIndex, exerciseIndex, innerExerciseIndex) => {
        const exercise = subscription.courses[0].days[dayIndex].exercises[exerciseIndex].exercises[innerExerciseIndex];
        setExerciseToDeleteInfo({
          dayIndex,
          exerciseIndex,
          innerExerciseIndex,
          exerciseName: exercise.name.en, // or exercise.name.ar
        });
        setShowDeleteConfirmation(true);
      };
    
      const handleConfirmDeleteExercise = () => {
        const { dayIndex, exerciseIndex, innerExerciseIndex } = exerciseToDeleteInfo;
    
        // Perform the delete action here (API call or state update)
        handleDeleteExerciseGroup(dayIndex, exerciseIndex, innerExerciseIndex);
    
        // Close the confirmation dialog
        setShowDeleteConfirmation(false);
      };

    const handleCategorySelection = (e) => {
        setSelectedCategory(e.target.value);
      };

    const handleDeleteExerciseGroup = (dayIndex, exerciseIndex) => {
        setIsLoading(true)
        const updatedCourse = {
            ...subscription.courses[0],
            days: subscription.courses[0].days.map((day, index) => {
                if (index === dayIndex) {
                    return {
                        ...day,
                        exercises: day.exercises.filter((_, exIndex) => exIndex !== exerciseIndex),
                    };
                }
                return day;
            }),
        };

        api.put(`/course/${id}/courses`, { course: updatedCourse })
            .then(response => {
                setSubscription(response.data);
            })
            .catch(error => {
                console.error('Error deleting exercise group:', error);
            })  .finally(() => {
                // Set isLoading back to false when the API call is complete
                setIsLoading(false);
              });
    };


    const handleExerciseSelection = e => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedExercises(selectedOptions);
    };

    // const handleExerciseSelection = e => {
    //     const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    //     setSelectedExercises(selectedOptions);
    // };

  const fetchData = () => {
    api.get('/exerciseCategory/exercise-categories')
    .then((response) => {
      setCategories(response.data);
    })
    .catch((error) => {
      console.error('Error fetching categories:', error);
    });
    // Fetch existing exercises
    api.get('/exercises')
        .then(response => {
            setExercises(response.data);
        })
        .catch(error => {
            console.error('Error fetching exercises:', error);
        });
        api.get(`/sub/${id}`)
        .then(response => {
            setSubscription(response.data);
            //  console.log(response.data.courses[0].days[1].exercises[1].exercises[1].name)
        })
        .catch(error => {
            console.error('Error fetching subscription data:', error);
        });
  }

    useEffect(() => {

        api.get('/exerciseCategory/exercise-categories')
        .then((response) => {
          setCategories(response.data);
        })
        .catch((error) => {
          console.error('Error fetching categories:', error);
        });
        // Fetch existing exercises
        api.get('/exercises')
            .then(response => {
                setExercises(response.data);

            })
            .catch(error => {
                console.error('Error fetching exercises:', error);
            });
    }, []);

    useEffect(() => {
        api.get(`/sub/${id}`)
            .then(response => {
                setSubscription(response.data);
                //  console.log(response.data.courses[0].days[1].exercises[1].exercises[1].name)
            })
            .catch(error => {
                console.error('Error fetching subscription data:', error);
            });
    }, []);

    const handleAddCourse = () => {
        api.post(`/course/${userId}/courses`, { name: newCourseName })
            .then(response => {
                setSubscription(response.data);
                setNewCourseName('');
                setIsAddingCourse(false);
            })
            .catch(error => {
                console.error('Error adding course:', error);
            });
    };

    const handleAddDay = () => {
        if (newDayNumber && subscription) {
            const updatedCourse = {
                ...subscription.courses[0],
                days: [
                    ...subscription.courses[0].days,
                    { dayNumber: parseInt(newDayNumber), exercises: [] }
                ]
            };

            api.put(`/course/${id}/courses`, { course: updatedCourse })
                .then(response => {
                    setSubscription(response.data);
                    setNewDayNumber('');
                    setIsAddingDay(false); // Set isAddingDay to false after adding day
                })
                .catch(error => {
                    console.error('Error adding day:', error);
                });
        }
    };

    const handleAddExercise = (dayIndex, reps, sets, superset) => {
        setIsLoading(true);
        if (subscription) {
            const updatedCourse = {
                ...subscription.courses[0],
                days: subscription.courses[0].days.map((day, index) => {
                    if (index === dayIndex) {
                        return {
                            ...day,
                            exercises: [
                                ...day.exercises,
                                {
                                    exercises: selectedExercises, // Use selectedExercises array
                                    reps: reps,
                                    sets: sets,
                                    superset: superset,
                                },
                            ],
                        };
                    }
                    return day;
                }),
            };

            api.put(`/course/${id}/courses`, { course: updatedCourse })
                .then(response => {
                    setSubscription(response.data);
                    setSelectedExercises([]); // Clear selected exercises after adding
                    setNewReps(8);
                    setNewSets(4);
                    setNewSuperset(false);
                    setIsAddingExercise(null);
                })
                .catch(error => {
                    console.error('Error adding exercise:', error);
                }).finally(() => {
                    // Set isLoading back to false when the API call is complete
                    setIsLoading(false);
                  });
        }
    };

    if (isLoading) {
        // useEffect(() => {
        // fetchExercises();
        // }, []);
        fetchData();
      
        return  <LoadingPage/>;
      }

    return (
        <Card className="mb-4">
            <Card.Header>Diet Information</Card.Header>
            <Card.Body>
                <div>
                    {subscription ? (
                        <div>
                            {subscription.courses.length > 0 ? (
                                <div>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Your Current Course:</Card.Title>
                                            <Card.Text>Name: {subscription.courses[0].name}</Card.Text>
                                            <Card.Text>Description: {subscription.courses[0].description}</Card.Text>
                                            <Button variant="primary" onClick={() => setIsAddingDay(true)}>Add Day</Button>
                                            {isAddingDay && (
                                                <div>
                                                    <input
                                                        type="number"
                                                        placeholder="Enter day number"
                                                        value={newDayNumber}
                                                        onChange={e => setNewDayNumber(e.target.value)}
                                                    />
                                                    <button onClick={handleAddDay}>Add Day</button>
                                                    <button onClick={() => setIsAddingDay(false)}>Cancel</button>
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Card>

                                    {subscription.courses[0].days.map((day, dayIndex) => (
                                        <Card key={dayIndex} className="mt-3">
                                            <Card.Header>Day {day.dayNumber}</Card.Header>

                                            <Card.Body>
                                                <Button variant="success" onClick={() => setIsAddingExercise(dayIndex)}>Add Exercise</Button>
                                                {isAddingExercise === dayIndex && (
  <div>
    <h5>Add Exercise</h5>
    <Form>
      <Form.Group controlId="selectCategory">
        <Form.Label>Select Category</Form.Label>
        <Form.Select onChange={handleCategorySelection}>
          <option value="">Select a category</option>
          {categories.map(category => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="selectExercises">
        <Form.Label>Select Exercises</Form.Label>
        <Form.Select
          multiple
          onChange={handleExerciseSelection}
        >
          <option value="">Select exercises</option>
          {exercises
            .filter(exercise =>
              selectedCategory
                ? exercise.category?._id === selectedCategory
                : true
            )
            .map(exercise => (
              <option key={exercise._id} value={exercise._id}>
                {exercise.name.en} - {exercise.name.ar}
              </option>
            ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="reps">
        <Form.Label>Reps</Form.Label>
        <Form.Control
          type="number"
          placeholder="Reps"
          value={newReps}
          onChange={e => setNewReps(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="sets">
        <Form.Label>Sets</Form.Label>
        <Form.Control
          type="number"
          placeholder="Sets"
          value={newSets}
          onChange={e => setNewSets(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="supersetCheckbox">
        <Form.Check
          type="checkbox"
          label="Superset"
          checked={newSuperset}
          onChange={() => setNewSuperset(!newSuperset)}
        />
      </Form.Group>

      <Button
        variant="primary"
        className="me-2"
        onClick={() =>
          handleAddExercise(dayIndex, newReps, newSets, newSuperset)
        }
      >
        Add Exercise
      </Button>

      <Button
        variant="secondary"
        onClick={() => setIsAddingExercise(null)}
      >
        Cancel
      </Button>
    </Form>
  </div>
)}



                                                <Table striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>Exercise</th>
                                                            <th>Name</th>
                                                            <th>Reps</th>
                                                            <th>Sets</th>
                                                            <th>Superset</th>
                                                            <th>Action</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {day.exercises.map((exercise, exerciseIndex) => (
    <React.Fragment key={exerciseIndex}>
        {exercise.exercises.map((innerExercise, innerExerciseIndex) => (
            <tr key={innerExerciseIndex}>
                {innerExerciseIndex === 0 ? (
                              <td
                              rowSpan={exercise.exercises.length} className='table-info'
                              style={{
                                verticalAlign: 'middle', // Center vertically
                              }}
                            >
                        
                              Exercise {exerciseIndex + 1}</td>
                ) : null}
                <td>{innerExercise.name ? innerExercise.name.en : 'N/A'}</td>
                <td>{exercise.reps}</td>
                <td>{exercise.sets}</td>
                <td>{exercise.superset ? 'Yes' : 'No'}</td>
                {innerExerciseIndex === 0 ? (
                    <td rowSpan={exercise.exercises.length}  style={{
                        verticalAlign: 'middle', // Center vertically
                      }}
                    >
                        {/* Action buttons */}
                        
                        <Button
                      variant="danger"
                      onClick={() =>
                        handleShowDeleteConfirmation(
                          dayIndex,
                          exerciseIndex,
                          innerExerciseIndex
                        )
                      }
                    >Delete Exercise</Button>
                    </td>
                ) : null}
            </tr>
        ))}
    </React.Fragment>
))}

                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>
                            ) : (
                                <p>
                                    You don't have an active course.
                                    <AddCourseForm subscriptionId={subscription} />
                                </p>
                            )}
                        </div>
                    ) : (
                        <p>Loading subscription data...</p>
                    )}
                </div>
            </Card.Body>
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the exercise: <strong>{exerciseToDeleteInfo?.exerciseName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDeleteExercise}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
        </Card>
    );
}



export default CourseManagementPage;
