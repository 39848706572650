import api from "../../api";
import React, { useState } from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const Login = () => {
    const loginAPI = 'http://192.168.80.11:5000/api/auth/login';
    const navigate = useNavigate();
  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const submitLoginForm = async (event) => {
      event.preventDefault();
  
      try {
        const response = await api.post('/auth/login', {
          phoneNumber: email,
          password: password
        });
  
        const { authToken } = response.data;
        if (!authToken) {
          alert('Unable to login. Please try after some time.');
          return;
        }
  
        // Decode the JWT token to get additional information
        const decodedToken = jwtDecode(authToken);
        const isAdmin = decodedToken.isAdmin;
  
        if (isAdmin) {
          // Optionally, you can redirect to an admin dashboard
          localStorage.setItem('accessToken', authToken);
          navigate('/');
        } else {
          // Redirect to the protected route
          alert('Unable to login. Please try after some time.');
          return;
         
        }
      } catch (error) {
        console.error('Login error:', error);
        alert("Oops! Some error occurred.");
      }
    }
  


  return (
<React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Login To Pooboo</h2>
                <Row>
                    <Col md={{ span: 6 }}>
                        <Form onSubmit={submitLoginForm}>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor="login-email">Username</FormLabel>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="login-email"
                                    name="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor="login-password">Password</FormLabel>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="login-password"
                                    name="password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormGroup>
                            <Button type="submit" className="btn-success mt-2" id="login-btn">
                                Login
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
  );
}

export default Login;
