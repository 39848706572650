import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './auth/login/Login';
import Auth from './auth/Auth';
import App from './App';
import ProtectedRoute from './util/ProtectedRoute';
import Home from './portal/home/Home';
import UserList from './portal/users/UserList'; // Import the UserList component
import EditUser from './portal/users/EditUser';
import SubscriptionList from './portal/subscription/SubscriptionList';
import SubscriptionDetailsPage from './portal/subscription/SubscriptionDetailsPage';
import CourseManagementPage from './portal/subscription/CourseManagementPage';
import CategoryList from './portal/ExerciseCategory/CategoryList';
import ExerciseList from './portal/Exercises/Exercises';
import FoodCategory from './portal/Food/FoodCategory';
import Food from './portal/Food/food';
import Posts from './portal/posts/Posts';
import PostCategories from './portal/posts/PostCategories';
import Products from './portal/products/Products';
import EditProductPage from './portal/products/EditProductPage';
import AddProductPage from './portal/products/AddProductPage';
import CreateCategory from './portal/category/CreateCategory';
import Category from './portal/category/CategoryList';
import Slider from './portal/slider/Slider';
import Orders from './portal/order/Orders';
import OrderDetails from './portal/order/OrderDetails';
import DeliveryPage from './portal/delivery/Delivery';
import AddUser from './portal/users/AddUser';
import NotificationForm from './portal/users/Notification';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter basename={'/'}>
			<Routes>
				<Route path='/auth' element={<Auth />}>
					<Route path='login' element={<Login />} />
				</Route>
				<Route path="/" element={<App />}>
					{/* <Route path="user-list" element={<ProtectedRoute> <UserList /> </ProtectedRoute>}>
					</Route>
					<Route path="edit/:id" element={<ProtectedRoute>  <EditUser /> </ProtectedRoute>} /> */}
					<Route path="subscriber" element={<ProtectedRoute>  <SubscriptionList /> </ProtectedRoute>} />
					<Route path="/subscriptions/:id/*" element={  <ProtectedRoute> <SubscriptionDetailsPage/>  </ProtectedRoute>} /> {/* New route */}
					<Route path="/course/:id/*" element={ <ProtectedRoute> <CourseManagementPage /> </ProtectedRoute> } /> {/* New route */}

					<Route path="/exercise-category/*" element={ <ProtectedRoute><CategoryList />  </ProtectedRoute>} /> {/* New route */}

					<Route path="/Exercises/*" element={ <ProtectedRoute><ExerciseList /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/food-category/*" element={ <ProtectedRoute><FoodCategory /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/foods/*" element={ <ProtectedRoute><Food /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/posts/*" element={ <ProtectedRoute><Posts /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/products/*" element={ <ProtectedRoute><Products /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/products/edit-product/:productId" element={ <ProtectedRoute><EditProductPage /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/products/add-product" element={ <ProtectedRoute><AddProductPage /> </ProtectedRoute>} /> {/* New route */}
				
					<Route path="/category/*" element={ <ProtectedRoute><Category /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/category/edit-category/:productId" element={ <ProtectedRoute><EditProductPage /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/category/add-category" element={ <ProtectedRoute><CreateCategory /> </ProtectedRoute>} /> {/* New route */}

					<Route path="/slider/*" element={ <ProtectedRoute><Slider /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/orders/*" element={ <ProtectedRoute><Orders /> </ProtectedRoute>} /> {/* New route */}
					<Route path="/orders/:orderId" element={ <ProtectedRoute><OrderDetails /> </ProtectedRoute>} /> {/* New route */}

					{/* <Route path="/users/" element={ <ProtectedRoute><UserList /> </ProtectedRoute>} /> {/* New route */}
					{/* <Route path="/users/edit/:id" component={<ProtectedRoute><EditUser/> </ProtectedRoute>} /> */} 
					<Route path="/users" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
        <Route path="/users/edit/:id" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
        <Route path="/users/add" element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
        <Route path="/users/notification" element={<NotificationForm><AddUser /></NotificationForm>} />

		<Route path="/delivery" element={<ProtectedRoute><DeliveryPage /></ProtectedRoute>} />

					<Route path="/posts-categories/*" element={ <ProtectedRoute><PostCategories /> </ProtectedRoute>} /> {/* New route */}

					<Route path='' element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					} />
				</Route>


			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
