import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Container, Image } from 'react-bootstrap';
import api from '../../api';
import ImgUrl from '../../util/ImgUrl';
import { useAuth } from '../../util/AuthContext';

const DeleteModal = ({ show, handleClose, handleDelete }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Slider</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this slider?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const Slider = () => {
    const [sliders, setSliders] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [sliderData, setSliderData] = useState({
        productId: '',
        categoryId: '',
        image: null,
    });
    const [editSliderId, setEditSliderId] = useState(null);
    const [sliderToDelete, setSliderToDelete] = useState(null);
    const { accessToken } = useAuth();
    useEffect(() => {
        // Fetch sliders from the API
        const fetchSliders = async () => {
            try {
                const response = await api.get('/slider');
                setSliders(response.data);
            } catch (error) {
                console.error('Error fetching sliders:', error);
            }
        };

        fetchSliders();
    }, []);

    const handleShowAddModal = () => {
        setSliderData({
            productId: '',
            categoryId: '',
            image: null,
        });
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowEditModal = (sliderId) => {
        const sliderToEdit = sliders.find((slider) => slider._id === sliderId);
        setSliderData({
            productId: sliderToEdit.productId,
            categoryId: sliderToEdit.categoryId,
            image: sliderToEdit.image || null,
        });
        setEditSliderId(sliderId);
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setEditSliderId(null);
        setShowEditModal(false);
    };

    const handleShowDeleteModal = (sliderId) => {
        setSliderToDelete(sliderId);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setSliderToDelete(null);
        setShowDeleteModal(false);
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setSliderData((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? e.target.files[0] : value,
        }));
    };

    const handleAddSlider = async () => {
        try {
            const formData = new FormData();
            formData.append('productId', sliderData.productId);
            formData.append('categoryId', sliderData.categoryId);
            formData.append('image', sliderData.image);

            await api.post('/slider', formData, {
                headers: {
                    token: `Bearer ${accessToken}`,
                }
            });
            handleCloseAddModal();
            const response = await api.get('/slider');
            setSliders(response.data);
        } catch (error) {
            console.error('Error adding slider:', error);
        }
    };

    const handleEditSlider = async () => {
        try {
            const formData = new FormData();
            formData.append('productId', sliderData.productId);
            formData.append('categoryId', sliderData.categoryId);
            formData.append('image', sliderData.image);

            await api.put(`/slider/${editSliderId}`, formData, {
                headers: {
                    token: `Bearer ${accessToken}`,
                }
            });
            handleCloseEditModal();
            const response = await api.get('/slider');
            setSliders(response.data);
        } catch (error) {
            console.error('Error editing slider:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await api.delete(`/slider/${sliderToDelete}`, {
                headers: {
                    token: `Bearer ${accessToken}`,
                }
            });
            const response = await api.get('/slider');
            setSliders(response.data);
            handleCloseDeleteModal();
        } catch (error) {
            console.error('Error deleting slider:', error);
        }
    };

    return (
        <Container className="py-5">
            <h2>Sliders</h2>

            <Button variant="primary" onClick={handleShowAddModal}>
                Add Slider
            </Button>

            <Table responsive striped bordered hover className="mt-3">
                <thead>
                    <tr>
                        <th>ID</th>

                        <th>Image</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sliders.map((slider) => (console.log(`${ImgUrl}/${slider.image}`),
                        <tr key={slider._id}>
                            <td>{slider._id}</td>

                            <td> {slider.image && (
                                <Image
                                    src={`${ImgUrl}/${slider.image}`} // Replace 'your_base_url' with the actual base URL for your images
                                    // alt="Slider Image"
                                    style={{ maxWidth: '100px', maxHeight: '80px' }} // Adjust the size as needed
                                />
                            )}</td>
                            <td>
                                <Button variant="warning" onClick={() => handleShowEditModal(slider._id)}>
                                    Edit
                                </Button>{" "}
                                <Button variant="danger" onClick={() => handleShowDeleteModal(slider._id)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Add Slider Modal */}
            <Modal show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Slider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="sliderProductId">
                            <Form.Label>Product ID:</Form.Label>
                            <Form.Control
                                type="text"
                                name="productId"
                                value={sliderData.productId}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sliderCategoryId">
                            <Form.Label>Category ID:</Form.Label>
                            <Form.Control
                                type="text"
                                name="categoryId"
                                value={sliderData.categoryId}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sliderImage">
                            <Form.Label>Image:</Form.Label>
                            <Form.Control
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddSlider}>
                        Add Slider
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Slider Modal */}
            <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Slider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="sliderProductId">
                            <Form.Label>Product ID:</Form.Label>
                            <Form.Control
                                type="text"
                                name="productId"
                                value={sliderData.productId}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sliderCategoryId">
                            <Form.Label>Category ID:</Form.Label>
                            <Form.Control
                                type="text"
                                name="categoryId"
                                value={sliderData.categoryId}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sliderImage">
                            <Form.Label>Image:</Form.Label>
                            <Form.Control
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEditSlider}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Slider Modal */}
            <DeleteModal
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleDelete={handleConfirmDelete}
            />
        </Container>
    );
};

export default Slider;
