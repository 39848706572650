import React, { useState,useEffect } from 'react';
import api from '../../api';
function AddFoodForm({ onSubmit, foodCategories }) {
  const [newImage, setNewImage] = useState(null);
  const [formData, setFormData] = useState({
    name: {
      en: '',
      ar: '',
    },
    foodcategory: '',
    calories: 0,
    fat: 0,
    protein: 0,
    carb: 0,
    ingredients: {
      en: '',
      ar: '',
    },
    directions: {
      en: '',
      ar: '',
    },
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Split the field name into parts based on dot notation
    const parts = name.split('.');
    
    // Create a copy of the formData object
    const updatedFormData = { ...formData };
  
    // Traverse the nested structure and update the value
    let current = updatedFormData;
    for (let i = 0; i < parts.length - 1; i++) {
      current = current[parts[i]];
    }
    current[parts[parts.length - 1]] = value;

    // Update the state with the modified formData
    setFormData(updatedFormData);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

        // Create a FormData object to send both text and file data
        const AddFormData = new FormData();
  
        // Append text data to the FormData object
        AddFormData.append('name', JSON.stringify(formData.name));
        AddFormData.append('foodcategory', formData.foodcategory);
        AddFormData.append('calories', formData.calories);
        AddFormData.append('fat', formData.fat);
        AddFormData.append('protein', formData.protein);
        AddFormData.append('carb', formData.carb);
        AddFormData.append('ingredients', JSON.stringify(formData.ingredients));
        AddFormData.append('directions', JSON.stringify(formData.directions));
      
        // Append the image data if newImage is defined
        if (newImage) {
          AddFormData.append('image', newImage, newImage.name);
        }

    onSubmit(AddFormData);
  //  console.log(AddFormData); // Check the console to verify that the 'image' property is set

  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };



  return (
    <div>
      <h2>Add Food</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
          <label>English Name</label>
          <input
            type="text"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Arabic Name</label>
          <input
            type="text"
            name="name.ar" // Use square brackets for nested property
            value={formData.name.ar}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Food Category</label>
          <select
            name="foodcategory"
            value={formData.foodcategory}
            onChange={handleChange}
          >
            <option value="">Select a category</option>
            {foodCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name.en} - {category.name.ar} 
              </option>
            ))}
          </select>
        </div>
        
        <div>
          <label>Calories</label>
          <input
            type="number"
            name="calories"
            value={formData.calories}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Fat (g)</label>
          <input
            type="number"
            name="fat"
            value={formData.fat}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Protein (g)</label>
          <input
            type="number"
            name="protein"
            value={formData.protein}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Carbohydrates (g)</label>
          <input
            type="number"
            name="carb"
            value={formData.carb}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>English Ingredients</label>
          <input
            type="text"
            name="ingredients.en"
            value={formData.ingredients.en}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Arabic Ingredients</label>
          <input
            type="text"
            name="ingredients.ar"
            value={formData.ingredients.ar}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>English Directions</label>
          <input
            type="text"
            name="directions.en"
            value={formData.directions.en}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Arabic Directions</label>
          <input
            type="text"
            name="directions.ar"
            value={formData.directions.ar}
            onChange={handleChange}
          />
        </div>
        <div>
  <label>Image</label>
  <input
  type="file"
  name="image"
  onChange={(e) => {
    // updatedFormData.append('image', e.target.files[0], e.target.files[0].name);

    const updatedFormData = { ...formData };
    updatedFormData.image = e.target.files[0];
    setFormData(updatedFormData);
  }}
/>

<input
            type="file"
            name="image"
            onChange={handleImageChange}
          />
</div>
        <button type="submit">Add Food</button>
      </form>
    </div>
  );
}



export default AddFoodForm;
