import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Modal, Form, Container, Image } from 'react-bootstrap';
import api from '../../api';
import ImgUrl from '../../util/ImgUrl';
import { useAuth } from '../../util/AuthContext';



const DeleteModal = ({ show, handleClose, handleDelete }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this category?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};



const Category = () => {
    const [categories, setCategories] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const { accessToken } = useAuth();
    const [categoryData, setCategoryData] = useState({
        name: '',
        active: false,
        isMain: false,
        image: null,
        parentCategory: null, // New field for parent category
    });
    const [editCategoryId, setEditCategoryId] = useState(null);

    useEffect(() => {
        // Fetch categories from the API
        const fetchCategories = async () => {
            try {
                const response = await api.get('/category/categories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleShowAddModal = () => {
        setCategoryData({
            name: '',
            active: false,
            image: null,
            isMain: false,

            parentCategory: null,
        });
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowEditModal = (categoryId) => {
        const categoryToEdit = categories.find((category) => category._id === categoryId);
        setCategoryData({
            name: categoryToEdit.name,
            active: categoryToEdit.active,
            isMain: categoryToEdit.isMain,

            image: categoryToEdit.image || null,
            parentCategory: categoryToEdit.parentCategory || null,
        });
        setEditCategoryId(categoryId);
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setEditCategoryId(null);
        setShowEditModal(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Check if the changed field is the "Main Category" checkbox
        if (name === "isMain") {
            setCategoryData((prevData) => ({
                ...prevData,
                [name]: checked,
                // If the checkbox is checked, set parentCategory to null
                parentCategory: checked ? null : prevData.parentCategory,
            }));
        } else {
            // For other fields, handle changes normally
            setCategoryData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };


    const handleImageChange = (e) => {
        setCategoryData({
            ...categoryData,
            image: e.target.files[0],
        });
    };

    const handleAddCategory = async () => {
        try {
            const formData = new FormData();
            formData.append('name', categoryData.name);
            formData.append('active', categoryData.active);
            formData.append('isMain', categoryData.isMain);
            formData.append('image', categoryData.image);
            formData.append('parentCategory', categoryData.parentCategory);

            await api.post('/category/categories', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: `Bearer ${accessToken}`,
                }
            },);
            handleCloseAddModal();
            // Refresh categories after adding a new one
            const response = await api.get('/category/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const handleEditCategory = async () => {
        try {
            const formData = new FormData();
            formData.append('name', categoryData.name);
            formData.append('active', categoryData.active);
            formData.append('isMain', categoryData.isMain);

            formData.append('image', categoryData.image);
            formData.append('parentCategory', categoryData.parentCategory);

            await api.put(`/category/categories/${editCategoryId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: `Bearer ${accessToken}`,
                }
            },);
            handleCloseEditModal();
            // Refresh categories after editing
            const response = await api.get('/category/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error editing category:', error);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            await api.delete(`/category/categories/${categoryId}`, {
                headers: {
                    token: `Bearer ${accessToken}`,
                }
            },);
            // Refresh categories after deleting
            const response = await api.get('/category/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await api.delete(`/category/categories/${categoryToDelete}`, {
                headers: {
                    token: `Bearer ${accessToken}`,
                }
            },);
            const response = await api.get('/category/categories');
            setCategories(response.data);
            handleCloseDeleteModal();
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };
    const handleShowDeleteModal = (categoryId) => {
        setCategoryToDelete(categoryId);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setCategoryToDelete(null);
        setShowDeleteModal(false);
    };




    return (

        <Container className='py-5'>
            <Card className="mb-4">
                <Card.Header>
                    <h2>Edit List</h2>
                </Card.Header>
                <Card.Body>
                <h2>Categories</h2>

                <Button variant="primary" onClick={handleShowAddModal}>
                    Add Category
                </Button>

                <Table responsive striped bordered hover className="mt-3">
                    <thead>
                        <tr>

                            <th>Name</th>
                            <th>Active</th>
                            <th>Image</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category) => (
                            <tr key={category._id}>

                                <td>{category.name}</td>
                                <td>{category.active ? 'Yes' : 'No'}</td>
                                <td> {category.image && (
                                    <Image
                                        src={`${ImgUrl}/${category.image}`} // Replace 'your_base_url' with the actual base URL for your images
                                        // alt="Slider Image"
                                        style={{ maxWidth: '100px', maxHeight: '80px' }} // Adjust the size as needed
                                    />
                                )}</td>
                                <td>{category.isMain ? 'Main Category' : 'Sub Category'}</td>
                                <td>
                                    <Button variant="warning" onClick={() => handleShowEditModal(category._id)}>
                                        Edit
                                    </Button>{" "}
                                    <Button variant="danger" onClick={() => handleShowDeleteModal(category._id)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <DeleteModal
                    show={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    handleDelete={handleConfirmDelete}
                />
                {/* Add Category Modal */}
                <Modal show={showAddModal} onHide={handleCloseAddModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="categoryName">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={categoryData.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryActive">
                                <Form.Check
                                    type="checkbox"
                                    label="Active"
                                    name="active"
                                    checked={categoryData.active}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryMain">
                                <Form.Check
                                    type="checkbox"
                                    label="Main Category ?"
                                    name="isMain"
                                    checked={categoryData.isMain}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryImage">
                                <Form.Label>Image:</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryParent">
                                <Form.Label>Parent Category:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="parentCategory"
                                    value={categoryData.parentCategory || ''}
                                    onChange={handleChange}
                                >
                                    <option value={null}>Main Category</option>
                                    {categories
                                        .filter((cat) => cat._id !== categoryData._id && cat.isMain) // Exclude the current category and only include main categories
                                        .map((mainCategory) => (
                                            <option key={mainCategory._id} value={mainCategory._id}>
                                                {mainCategory.name}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAddCategory}>
                            Add Category
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Edit Category Modal */}
                <Modal show={showEditModal} onHide={handleCloseEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="categoryName">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={categoryData.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryActive">
                                <Form.Check
                                    type="checkbox"
                                    label="Active"
                                    name="active"
                                    checked={categoryData.active}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryMain">
                                <Form.Check
                                    type="checkbox"
                                    label="Main Category ?"
                                    name="isMain"
                                    checked={categoryData.isMain}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryImage">
                                <Form.Label>Image:</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryParent" style={{ display: categoryData.isMain ? 'none' : 'block' }}>
                                <Form.Label>Parent Category:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="parentCategory"
                                    value={categoryData.parentCategory || ''}
                                    onChange={handleChange}
                                >
                                    <option value={null}>null</option>
                                    {categories
                                        .filter((cat) => cat._id !== categoryData._id && cat.isMain) // Exclude the current category and only include main categories
                                        .map((mainCategory) => (
                                            <option key={mainCategory._id} value={mainCategory._id}>
                                                {mainCategory.name}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEditModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleEditCategory}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                </Card.Body>
                </Card>
                
        </Container>
    );
};

export default Category;
