import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';

const CreateCategory = () => {
  const [categoryData, setCategoryData] = useState({
    name: '',
    parentCategory: null,
    image: '',
    active: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to create a new category
      await axios.post('http://localhost:3000/categories', categoryData);

      // Optionally, you can reset the form or redirect to the category list
      setCategoryData({
        name: '',
        parentCategory: null,
        image: '',
        active: true,
      });
    } catch (error) {
      console.error('Error creating category:', error);
    }
  };

  return (
    <div>
      <h2>Create Category</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="categoryName">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={categoryData.name}
            onChange={handleChange}
          />
        </Form.Group>
        {/* Add Form.Group for other category properties */}
        <Button variant="primary" type="submit">
          Create Category
        </Button>
      </Form>
    </div>
  );
};

export default CreateCategory;
