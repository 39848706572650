import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Container } from 'react-bootstrap';
import api from '../../api';
import { useAuth } from '../../util/AuthContext';

const DeleteModal = ({ show, handleClose, handleDelete, deliveryId  }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Delivery Option</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this delivery option?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDelete(deliveryId)}>
                    Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeliveryPage = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deliveryToDelete, setDeliveryToDelete] = useState(null);
  const [deliveryData, setDeliveryData] = useState({
    title: '',
    price: 0,
  });
  const [editDeliveryId, setEditDeliveryId] = useState(null);
const { accessToken } = useAuth();
  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const response = await api.get('/delivery');
        setDeliveries(response.data);
      } catch (error) {
        console.error('Error fetching deliveries:', error);
      }
    };

    fetchDeliveries();
  }, []);

  const handleShowAddModal = () => {
    setDeliveryData({
      title: '',
      price: 0,
    });
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => setShowAddModal(false);

  const handleShowEditModal = (deliveryId) => {
    const deliveryToEdit = deliveries.find((delivery) => delivery._id === deliveryId);
    setDeliveryData({
      title: deliveryToEdit.title,
      price: deliveryToEdit.price,
    });
    setEditDeliveryId(deliveryId);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditDeliveryId(null);
    setShowEditModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeliveryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddDelivery = async () => {
    try {
      await api.post('/delivery', deliveryData, {headers: {
        token: `Bearer ${accessToken}`,
      }});
      handleCloseAddModal();
      const response = await api.get('/delivery');
      setDeliveries(response.data);
    } catch (error) {
      console.error('Error adding delivery option:', error);
    }
  };

  const handleEditDelivery = async () => {
    try {
      await api.put(`/delivery/${editDeliveryId}`, deliveryData, {headers: {
        token: `Bearer ${accessToken}`,
      }});
      handleCloseEditModal();
      const response = await api.get('/delivery');
      setDeliveries(response.data);
    } catch (error) {
      console.error('Error editing delivery option:', error);
    }
  };

  const handleConfirmDelete3 = async (deliveryId) => {
    try {
      await api.delete(`/delivery/${deliveryId}`, {headers: {
        token: `Bearer ${accessToken}`,
      }});
      const response = await api.get('/delivery');
      setDeliveries(response.data);
      handleCloseDeleteModal();

    } catch (error) {
      console.error('Error deleting delivery option:', error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/delivery/${deliveryToDelete}`, {headers: {
        token: `Bearer ${accessToken}`,
      }});
      console.log(deliveryToDelete)
      const response = await api.get('/delivery');
      setDeliveries(response.data);
      handleCloseDeleteModal();
    } catch (error) {
      console.error('Error deleting delivery option:', error);
    }
  };

  const handleShowDeleteModal = (deliveryId) => {
    setDeliveryToDelete(deliveryId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeliveryToDelete(null);
    setShowDeleteModal(false);
  };

  return (
    <Container className="py-5">
      <h2>Delivery Options</h2>

      <Button variant="primary" onClick={handleShowAddModal}>
        Add Delivery Option
      </Button>

      <Table responsive striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {deliveries.map((delivery) => (
            <tr key={delivery._id}>
              <td>{delivery._id}</td>
              <td>{delivery.title}</td>
              <td>{delivery.price}</td>
              <td>
                <Button variant="warning" onClick={() => handleShowEditModal(delivery._id)}>
                  Edit
                </Button>{' '}
                <Button variant="danger" onClick={() => handleShowDeleteModal(delivery._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleConfirmDelete}
        deliveryId={deliveryToDelete}
      />
      {/* Add Delivery Option Modal */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Delivery Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="deliveryTitle">
              <Form.Label>Title:</Form.Label>
              <Form.Control type="text" name="title" value={deliveryData.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="deliveryPrice">
              <Form.Label>Price:</Form.Label>
              <Form.Control type="number" name="price" value={deliveryData.price} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddDelivery}>
            Add Delivery Option
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Delivery Option Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Delivery Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="deliveryTitle">
              <Form.Label>Title:</Form.Label>
              <Form.Control type="text" name="title" value={deliveryData.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="deliveryPrice">
              <Form.Label>Price:</Form.Label>
              <Form.Control type="number" name="price" value={deliveryData.price} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditDelivery}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DeliveryPage;
