import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Card,InputGroup, FormControl,Container } from 'react-bootstrap';
import AddProductForm from './AddProductForm'; // Adjust the path as needed
import EditProductForm from './EditProductForm'; // Adjust the path as needed
import { Link } from 'react-router-dom';
import api from '../../api';
import Paginate from '../../util/Paginate';
import { useAuth } from '../../util/AuthContext';
import LoadingPage from '../../util/Loading';
import { formatPrice } from '../../util/formatPrice';
import { faSearch, faEdit, faTrash, faAdd, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Products() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); // New state for loading
const {accessToken} = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await api.get('/products/adminproducts',   {headers: {
            token: `Bearer ${accessToken}`,
          }},);
        setProducts(productsResponse.data);
        setLoading(false); // Set loading to false after data is fetched
    console.log(productsResponse.data.length)

        const categoriesResponse = await api.get('/category/categories');
        setProductCategories(categoriesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const filteredProducts = products.filter((product) =>
  product.name.toLowerCase().includes(searchTerm.toLowerCase())
);

  const handleShowAddModal = () => setShowAddModal(true);
  const handleShowEditModal = (product) => {
    setSelectedProduct(product);
    setShowEditModal(true);
  };
  const handleShowDeleteModal = (product) => {
    setSelectedProduct(product);
    setShowDeleteModal(true);
  };
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setShowEditModal(false);
  };
  const handleCloseDeleteModal = () => {
    setSelectedProduct(null);
    setShowDeleteModal(false);
  };

  const handleAddProduct = async (newProduct) => {
    try {
      const response = await api.post('/products', newProduct);
      setProducts([...products, response.data]);
      handleCloseAddModal();
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleEditProduct = async (editedProduct) => {
    try {
      await api.put(`/products/edit/${selectedProduct._id}`, editedProduct);
      const updatedProducts = products.map((product) =>
        product._id === selectedProduct._id ? { ...product, ...editedProduct } : product
      );
      setProducts(updatedProducts);
      handleCloseEditModal();
    } catch (error) {
      console.error('Error editing product:', error);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      await api.delete(`/products/${selectedProduct._id}` , {headers: {
        token: `Bearer ${accessToken}`,
      }});
      const updatedProducts = products.filter((product) => product._id !== selectedProduct._id);
      setProducts(updatedProducts);
      handleCloseDeleteModal();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Container className="py-5">
      <Card className="mb-4">
        <Card.Header>
          <h1>Product List</h1>
        </Card.Header>
        <Card.Body>
        <div className="d-flex align-items-center">

        <Link to={`/products/add-product`}>
                  <Button variant="primary" className="m-2" style={{fontWeight : "bold"}}>
                    Add Product {" "}
                    <FontAwesomeIcon icon={faAdd} />

                  </Button>
                </Link>


<InputGroup className="mr-2  w-25">
    <FormControl
      type="text"
      placeholder="Search for product name"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="w-25"
    />
    <InputGroup.Text>
      <FontAwesomeIcon icon={faSearch} style={{ color: 'blue' }} />
    </InputGroup.Text>
  </InputGroup>
</div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Product Price</th>
                <th>WholeSale</th>
                <th>WholeSale Plus</th>
                <th>Stock</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((product, index) => (
                <tr key={product._id}>
                  <td>{index + 1}</td>
                  <td>{product.name}</td>
                  <td>{formatPrice(product.price)}</td>
                  <td>{formatPrice(product.price_wholesale)}</td>
                  <td>{formatPrice(product.price_wholesale_plus)}</td>
                  <td>
    {product.quantity > 0 ? (
        <span style={{ color: 'green' }}>In Stock</span>
    ) : (
        <span style={{ color: 'red' }}>Out of Stock</span>
    )}
</td>

                  <td>
                  <Link to={`/products/edit-product/${product._id}`} >
                  <Button variant="primary" className="m-2" style={{fontWeight : "bold"}}>
                    Edit
                    {" "}
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Link>
                    <Button style={{fontWeight : "bold"}}
                      variant="danger"
                      onClick={() => handleShowDeleteModal(product)}
                      className="m-2"
                    >
                      Delete {" "}
                      <FontAwesomeIcon icon={faTrash} />

                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            itemsPerPage={itemsPerPage}
            totalItems={filteredProducts.length}
            currentPage={currentPage}
            paginate={paginate}
          />

          {/* Add Product Modal */}
          <Modal show={showAddModal} onHide={handleCloseAddModal}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <AddProductForm
                productCategories={productCategories}
                onSubmit={handleAddProduct}
              />
            </Modal.Body>
          </Modal>

          {/* Edit Product Modal */}
          <Modal show={showEditModal} onHide={handleCloseEditModal}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <EditProductForm
                productCategories={productCategories}
                product={selectedProduct}
                onSubmit={handleEditProduct}
              />
            </Modal.Body>
          </Modal>

          {/* Delete Product Modal */}
          <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are you sure you want to delete this product item{' '}
                {selectedProduct?.name}?
              </p>
              <Button variant="danger" onClick={handleDeleteProduct}>
                Delete
              </Button>
            </Modal.Body>
          </Modal>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Products;
