import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Form, Button, Row, Col, Card, } from 'react-bootstrap';
import api from '../../api';
import { useAuth } from '../../util/AuthContext';
import LoadingPage from '../../util/Loading';

const EditUser = () => {
  const { id } = useParams();
  const {accessToken} = useAuth();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    phoneNumber: '',
    verificationCode: '',
    isVerified: false,
    password: '', // For security reasons, consider not pre-filling the password
    isAdmin: false,
    userGroup: 'user',
    status: 'not_active',
    shopName: '',
    fcmToken: '',
    // Add other fields from your model
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get(`/user/${id}`, {
            headers: {
                token: `Bearer ${accessToken}`,
            },
        });
        setUser(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true)
        e.preventDefault();

    try {
      await api.put(`/user/${id}`, user,{
        headers: {
            token: `Bearer ${accessToken}`,
        },
    });
    setLoading(false)
    setSuccessMessage('User updated successfully');

      // Redirect or show a success message
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  if(loading) {
    return <LoadingPage/>
  }

  return (
    <Container className="py-5">
              {successMessage && (
    <div className="alert alert-success mt-3" role="alert">
        {successMessage}
    </div>
)}
      <Card className="mb-4">
                <Card.Header>
                <h2>Edit User</h2>
                </Card.Header>
                <Card.Body>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={user.firstname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={user.lastname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={user.username}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={user.email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            name="phoneNumber"
            value={user.phoneNumber}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            type="text"
            name="verificationCode"
            value={user.verificationCode}
            onChange={handleChange}
          />
        </Form.Group>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Is Verified"
                name="isVerified"
                checked={user.isVerified}
                onChange={(e) => handleChange({ target: { name: 'isVerified', value: e.target.checked } })}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Is Admin"
                name="isAdmin"
                checked={user.isAdmin}
                onChange={(e) => handleChange({ target: { name: 'isAdmin', value: e.target.checked } })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>User Group</Form.Label>
              <Form.Control
                as="select"
                name="userGroup"
                value={user.userGroup}
                onChange={handleChange}
              >
                <option value="user">User</option>
                <option value="wholesale">Wholesale</option>
                <option value="wholesale+">Wholesale+</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={user.status}
                onChange={handleChange}
              >
                <option value="active">Active</option>
                <option value="not_active">Not Active</option>
                <option value="banned">Banned</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Shop Name</Form.Label>
          <Form.Control
            type="text"
            name="shopName"
            value={user.shopName}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>FCM Token</Form.Label>
          <Form.Control
            type="text"
            name="fcmToken"
            value={user.fcmToken}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Add other fields as needed */}

        <Button variant="primary" type="submit">
          Save Changes
        </Button>
      </Form>
      </Card.Body>
      </Card>

    </Container>
  );
};

export default EditUser;
