import React, { useState } from 'react';
import api from '../../api'; // Import your API instance

function AddCourseForm({ subscriptionId }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleAddCourse = () => {
    const newCourse = {
      name,
      description,
      days: [], // You can initialize days here or get them from user input
    };

    api.post(`/course/${subscriptionId._id}/courses`, newCourse)
      .then(response => {
        console.log('New course added:', response.data);
        // Optionally, you can update the UI to show the added course
      })
      .catch(error => {
        console.error('Error adding course:', error);
      });
  };

  return (
    <div>
      {subscriptionId && (
        <>
          <h3>Add New Course</h3>
          {subscriptionId.courses.length === 0 && (
            <>
              <input
                type="text"
                placeholder="Course Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Course Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              <button onClick={handleAddCourse}>Add Course</button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AddCourseForm;
