import React, { useState, useEffect } from 'react';
import { Container, Button, Table, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Paginate from '../../util/Paginate';
import api from '../../api';

function SubscriptionList() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedSubscription, setSelectedSubscription] = useState(null); // To store the selected subscription
  const [showModal, setShowModal] = useState(false);

  const handleViewSubmissions = (subscription) => {
    setSelectedSubscription(subscription);
    setShowModal(true);
  };
  // ...

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subscriptions.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const userToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch subscription data from an API or your backends
    api.get('/sub/active')
      .then(response => {
        setSubscriptions(response.data);
        console.log(response.data)
        setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      })
      .catch(error => {
        console.error('Error fetching subscription data:', error);
      });
  }, []);

  // ...

  return (
    <div className="container mt-4">
      <h2>Subscription List</h2>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Subscription Type</th>
            <th>User</th>
            <td>Has Submission</td>
            <td>Has Course</td>
            <td>Has Diet</td>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map(subscription => (
            <tr key={subscription._id}>
              <td>{subscription._id}</td>
              <td>{subscription.subscription_type}</td>
              <td>{subscription.user.username}</td>
              <td>  {subscription.submissions.length  > 0 ?
                <Button variant="primary" onClick={() => navigate(`/subscriptions/${subscription._id}`)}>
                  View Submissions
                </Button> : 'No'}
              </td>
              <td>{subscription.courses.length > 0 ?  <Button variant="primary" onClick={() => navigate(`/course/${subscription._id}`)}>
                  course
                </Button> : <Button variant="primary" onClick={() => navigate(`/course/${subscription._id}`)}>
                  course
                </Button>}</td>
              <td>{subscription.diet.meals.length > 0 ? 'Yes' : 'No'}</td>
              <td>
                <Button
                  className="btn-warning"
                  onClick={() => navigate(`/edit/${subscription._id}`)}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <Paginate
          itemsPerPage={itemsPerPage}
          totalItems={subscriptions.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Submissions Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSubscription && selectedSubscription.submissions.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Gym</th>
                  {/* ... other columns ... */}
                </tr>
              </thead>
              <tbody>
                {selectedSubscription.submissions.map((submission, index) => (
                  <tr key={index}>
                    <td>{submission.name}</td>
                    <td>
                      <img src={`http://192.168.56.1:3000/uploads/${submission.images[1]}`} alt={submission.name} width={50} />
                    </td>
                    <td>{submission.gym}</td>
                    {/* ... other columns ... */}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No submissions available for this subscription.</p>
          )}
        </Modal.Body>
      </Modal>


    </div>
  );
}

export default SubscriptionList;
