// components/Orders.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Table, Card, InputGroup, FormControl, Row, Col, Container } from 'react-bootstrap';
import api from '../../api';
import { useAuth } from '../../util/AuthContext';
import Paginate from '../../util/Paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faTrash, faAdd, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '../../util/formatPrice';
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const { accessToken } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedDateFilter, setSelectedDateFilter] = useState(""); // Step 1: Date filter state
  const [searchQuery, setSearchQuery] = useState("");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);



  const filterOrders = () => {
    return orders.filter((order) => {
      const descriptionMatch = order?.status?.toLowerCase().includes(searchQuery.toLowerCase());
      const dateMatch =
        !selectedDateFilter ||
        new Date(order.createdAt).toISOString().startsWith(selectedDateFilter);
      const idMatch = order._id.includes(searchQuery.toLowerCase());
      const phoneMatch = order.shippingAddress?.mobileNo.includes(searchQuery);
      const shopNameMatch = order.userId?.shopName?.toLowerCase().includes(searchQuery.toLowerCase());
  
      return (descriptionMatch || idMatch || phoneMatch || shopNameMatch) && dateMatch;
    });
  };
  

  const filteredOrder = filterOrders();
  console.log(filteredOrder)
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get('/order', {
          headers: {
            token: `Bearer ${accessToken}`,
          }
        });
        setOrders(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const getStatusStyle = (status) => {
    switch (status) {
      case 'pending':
        return { color: 'orange', fontWeight: 'bold' };
      case 'shipped':
        return { color: 'green', fontWeight: 'bold' };
      case 'processing':
        return { color: 'red', fontWeight: 'bold' };
      case 'delivered':
        return { color: 'blue', fontWeight: 'bold' };
      default:
        return { fontWeight: 'bold' };
    }
  };


  return (
    <Container className="py-5">
      <Card className="mb-4">
        <Card.Header>
          <h1>Orders</h1>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center">
            <InputGroup className="mr-2  w-25">
              <FormControl
                type="text"
                placeholder="Search about"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-25"
              />
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} style={{ color: 'blue' }} />
              </InputGroup.Text>
            </InputGroup>

            <InputGroup className="m-2 w-25">
              <FormControl
                type="date"
                value={selectedDateFilter}
                onChange={(e) => setSelectedDateFilter(e.target.value)}
                className="w-25"
              />
            </InputGroup>
          </div>


          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Total Price</th>
                <th>Date</th>
                <th>Status</th>

                <th>User Name</th>
                <th>User Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrder
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{formatPrice(order.totalPrice)}</td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td style={getStatusStyle(order.status)}>{order.status}</td>

                    <td>{order.shippingAddress?.name}</td>
                    <td>{order.shippingAddress?.mobileNo}</td>
                    <td>
                      <Link to={`/orders/${order._id}`}>
                        View Details
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Paginate
            itemsPerPage={itemsPerPage}
            totalItems={filteredOrder.length}
            currentPage={currentPage}
            paginate={paginate}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Orders;
