// OrderDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Table, Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../api';
import { useAuth } from '../../util/AuthContext';
import { formatPrice } from '../../util/formatPrice';

const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [newStatus, setNewStatus] = useState('');
const {accessToken} = useAuth();
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await api.get(`/order/${orderId}`, {headers: {
          token: `Bearer ${accessToken}`,
        }});
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleStatusChange = async () => {
    try {
      await api.put(`/order/${orderId}/status`, { newStatus }, {headers: {
        token: `Bearer ${accessToken}`,
      }});
      // Refresh order details after updating status
      const response = await api.get(`/order/${orderId}`, {headers: {
        token: `Bearer ${accessToken}`,
      }});
      setOrder(response.data);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  return (
    <Container className="py-5">
      <h2>Order Details</h2>
      {order && (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Variation</th>
                <th>Price</th>
                {/* Add other columns as needed */}
              </tr>
            </thead>
            <tbody>
              {order.cartItems.map((item) => (
                <tr key={item.product._id}>
                  <td>{item.product.name}</td>
                  <td>{item.quantity}</td>
                  {item?.variation ? (
                          <td>{item?.variation}</td>

      ) : (
        <td>N/A</td>

      )}
                  <td >{formatPrice(item.displayPrice)}</td>
                  {/* Add other cells as needed */}
                </tr>
              ))}
            </tbody>
            {/* Total Row */}
            <tfoot>

            <tr>
                <td colSpan="3" className="text-right" style={{fontSize:20}}><strong>Delivery</strong></td>
                <td style={{fontSize:20, fontWeight:"bold"}}>{formatPrice(order.delivery?.price)}</td>
              </tr>
              <tr>
                <td colSpan="3" className="text-right" style={{fontSize:20}}><strong>Total</strong></td>
                <td style={{fontSize:20, fontWeight:"bold"}}>{formatPrice(order.totalPrice+order.delivery?.price)}</td>
              </tr>
            </tfoot>
          </Table>

          <Row className="mt-4">
            <Col md={6}>
              <h4>Shipping Address</h4>
              <div className="box">
                <p><strong>Name:</strong> {order.shippingAddress?.name}</p>
                <p><strong>City:</strong> {order.shippingAddress?.city}</p>
                <p><strong>Landmark:</strong> {order.shippingAddress?.landmark}</p>
                <p><strong>Mobile No:</strong> {order.shippingAddress?.mobileNo}</p>
                <p><strong>Mobile No 2nd:</strong> {order.shippingAddress?.mobileNo2nd}</p>
              </div>
            </Col>
     
            <Col md={2}>
              <h4>Order Status</h4>
              <p>Current Status: {order.status}</p>
              <Form.Group controlId="orderStatus">
                <Form.Label>Change Status</Form.Label>
                <Form.Control
                  as="select"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <option value="pending">Pending</option>
                  <option value="processing">Processing</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                </Form.Control>
              </Form.Group>
              <Button variant="primary" className="mt-2" onClick={handleStatusChange}>
                Update Status
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default OrderDetails;
