import React, { useEffect, useState } from 'react';
import api from '../../api'; // Import your API instance
import { Table, Button, Modal, Form, Card } from 'react-bootstrap'; // Import React Bootstrap components

function PostCategories() {
    const [categories, setCategories] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryNames, setNewCategoryNames] = useState({
      en: '',
      ar: '',
    });
    const [loading, setLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedCategory, setEditedCategory] = useState({ name: { en: '', ar: '' } });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletedCategory, setDeletedCategory] = useState({});
  
    const [categoryImage, setCategoryImage] = useState(null);

    // Add a new function to handle file selection
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setCategoryImage(file);
    };

  useEffect(() => {
   


    // Fetch categories from your backend API
    api.get('/categories')
      .then((response) => {
        setCategories(response.data);
        setLoading(false); // Set loading to false when data is retrieved
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);


  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  // const handleAddCategory = () => {
  //   // Send a POST request to create a new category with names in both languages
  //   api.post('/categories', {
  //     name: {
  //       en: newCategoryNames.en,
  //       ar: newCategoryNames.ar,
  //     },
  //   })
  //     .then((response) => {
  //       setCategories([...categories, response.data]);
  //       setNewCategoryNames({ en: '', ar: '' });
  //       setShowAddModal(false);
  //     })
  //     .catch((error) => {
  //       console.error('Error adding category:', error);
  //     });
  // };


  
  const handleAddCategory = () => {
    const formData = new FormData();
    formData.append('name', JSON.stringify({ en: newCategoryNames.en, ar: newCategoryNames.ar }));
    formData.append('image', categoryImage); // Add the image file to the form data
  
    // Send a POST request to create a new category with names and image
    api.post('/categories', formData)
      .then((response) => {
        // Your existing code to update the categories list
        setCategories([...categories, response.data]);
        setCategoryImage(null); // Clear the selected image after adding
        setNewCategoryNames({ en: '', ar: '' });
        setShowAddModal(false);
        // ...
      })
      .catch((error) => {
        console.error('Error adding category:', error);
      });
  };
  const handleShowEditModal = (category) => {
    setEditedCategory(category);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEditCategory = () => {
    // Create form data for the updated category
    const formData = new FormData();
    formData.append('name', JSON.stringify(editedCategory.name));
  
    // Check if a new image was selected
    if (categoryImage) {
      formData.append('image', categoryImage);
    }
  
    // Send a PUT request to update the category
    api.put(`/categories/${editedCategory._id}`, formData)
      .then((response) => {
        const updatedCategories = categories.map((category) => {
          if (category._id === editedCategory._id) {
            return response.data;
          }
          return category;
        });
        setCategories(updatedCategories);
        setShowEditModal(false);
      })
      .catch((error) => {
        console.error('Error editing category:', error);
      });
  };

  
    const handleShowDeleteModal = (category) => {
      const categoryName = category.name;
      const enName = categoryName.en;
      const arName = categoryName.ar;
    
      setDeletedCategory({
        ...category,
        enName: enName,
        arName: arName,
      });
      
      setShowDeleteModal(true);
    };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteCategory = () => {
    // Send a DELETE request to delete a category
    api.delete(`/categories/${deletedCategory._id}`)
      .then(() => {
        const updatedCategories = categories.filter((category) => category._id !== deletedCategory._id);
        setCategories(updatedCategories);
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error('Error deleting category:', error);
      });
  };
  const renderCategoryNames = (categoryName) => {
    return (
      <>
        <div>English: {categoryName.en}</div>
        <div>Arabic: {categoryName.ar}</div>
      </>
    );
  };
  const renderCategoryNames1 = (name) => {
    return (
      <>
        <span className="english-name">{name.en}</span> - <span className="arabic-name">{name.ar}</span>
      </>
    );
  };
  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <div className="container-fluid mt-4"> 
    <Card className="mb-4">
<Card.Header>  <h1>Exercise Categories</h1></Card.Header>
<Card.Body>
     
      <Button className="m-2" variant="primary" onClick={handleShowAddModal}>Add Category</Button>

      <Table striped bordered hover>
  <thead>
    <tr>
      <th>Category Names</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {categories.map((category) => (
      <tr key={category._id}>
        <td>
          {renderCategoryNames(category.name)}
        </td>
        <td>
          <Button variant="info" onClick={() => handleShowEditModal(category)}>Edit</Button>{' '}
          <Button variant="danger" onClick={() => handleShowDeleteModal(category)}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>

      {/* Modal for adding a new category */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
  <Modal.Header closeButton>
    <Modal.Title>Add New Category</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form.Group>
      <Form.Label>English Category Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter English category name"
        value={newCategoryNames.en}
        onChange={(e) =>
          setNewCategoryNames({
            ...newCategoryNames,
            en: e.target.value,
          })
        }
      />
    </Form.Group>
    <Form.Group>
      <Form.Label>Arabic Category Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter Arabic category name"
        value={newCategoryNames.ar}
        onChange={(e) =>
          setNewCategoryNames({
            ...newCategoryNames,
            ar: e.target.value,
          })
        }
      />
    </Form.Group>
    <Form.Group>
      <Form.Label>Category Image</Form.Label>
      <Form.Control type="file" onChange={handleImageChange} />
    </Form.Group>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseAddModal}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleAddCategory}>
      Add Category
    </Button>
  </Modal.Footer>
</Modal> 

      {/* Modal for editing an existing category */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
  <Modal.Header closeButton>
    <Modal.Title>Edit Category</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form.Group>
      <Form.Label>English Category Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Edit English category name"
        value={editedCategory.name.en}
        onChange={(e) => setEditedCategory({ ...editedCategory, name: { ...editedCategory.name, en: e.target.value } })}
      />
    </Form.Group>
    <Form.Group>
      <Form.Label>Arabic Category Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Edit Arabic category name"
        value={editedCategory.name.ar}
        onChange={(e) => setEditedCategory({ ...editedCategory, name: { ...editedCategory.name, ar: e.target.value } })}
      />
    </Form.Group>
    <Form.Group>
      <Form.Label>Category Image</Form.Label>
      <Form.Control type="file" onChange={handleImageChange} />
    </Form.Group>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseEditModal}>Cancel</Button>
    <Button variant="primary" onClick={handleEditCategory}>Save Changes</Button>
  </Modal.Footer>
</Modal>


{/* Modal for confirming category deletion */}
<Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
  <Modal.Header closeButton>
    <Modal.Title>Delete Category</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to delete the category: 
    <strong>{deletedCategory.enName} - {deletedCategory.arName} </strong>?
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
    <Button variant="danger" onClick={handleDeleteCategory}>Delete</Button>
  </Modal.Footer>
</Modal>




      </Card.Body>
      </Card>
    </div>
  );
}


export default PostCategories;

