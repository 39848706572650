import React, { useEffect, useState } from 'react';
import api from '../../api'; // Import your API instance
import { Table, Button, Modal, Form, Dropdown, Card } from 'react-bootstrap';
import LoadingPage from '../LoadingPage';
function ExerciseList() {
  const [exercises, setExercises] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newExerciseNameEn, setNewExerciseNameEn] = useState('');
  const [newExerciseNameAr, setNewExerciseNameAr] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(''); // New state for selected category
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exerciseImage, setExerciseImage] = useState(null);
  const [newExercise, setNewExercise] = useState({
    name: {
      en: '',
      ar: '',
    },
  });
  const [editedExercise, setEditedExercise] = useState({
    _id: '',
    name: {
      en: '',
      ar: '',
    },
    category: '', // New state for category
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedExercise, setDeletedExercise] = useState(null);


  const exercise = {
    _id: 'exerciseId', // Replace with the actual exercise ID
    name: {
      en: 'Exercise Name in English',
      ar: 'Exercise Name in Arabic',
    },
  };

  const fetchExercises = () => {
    api.get('/exercises')
      .then((response) => {
        setExercises(response.data);
      })
      .catch((error) => {
        console.error('Error fetching exercises:', error);
      });

      api.get('/exerciseCategory/exercise-categories')
      .then((response) => {
        setCategories(response.data);
        
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  useEffect(() => {
    // Fetch exercises from your backend API
    api.get('/exercises')
      .then((response) => {
        setExercises(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching exercises:', error);
      });

    // Fetch categories from your backend API
    api.get('/exerciseCategory/exercise-categories')
      .then((response) => {
        setCategories(response.data);
        
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);


  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

// Update your handleAddExercise function to send the image file to the API
const handleAddExercise = () => {
  setIsLoading(true);
  const formData = new FormData();
  formData.append('exerciseImage', exerciseImage); // Add the image to the FormData
  formData.append('name', JSON.stringify(newExercise.name)); // Use the name object
  formData.append('category', selectedCategory._id);

  api.post('/exercises', formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
    },
  })
  .then((response) => {
    setExercises([...exercises, response.data]);
    setNewExercise({
      name: {
        en: '',
        ar: '',
      },
    });
    setSelectedCategory('');
    setExerciseImage(null);
    setShowAddModal(false);
  })
  .catch((error) => {
    console.error('Error adding exercise:', error);
  })
  .finally(() => {
    setIsLoading(false);
  });
};

  const handleShowEditModal = (exercise) => {
    setEditedExercise(exercise);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

// Update your handleEditExercise function to send the image file to the API
const handleEditExercise = () => {
  setIsLoading(true);
  const formData = new FormData();
  formData.append('exerciseImage', exerciseImage); // Add the image to the FormData
  formData.append('name', JSON.stringify(editedExercise.name)); // Use the name object
  formData.append('category', editedExercise.category._id);

  api.put(`/exercises/${editedExercise._id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then((response) => {
    const updatedExercises = exercises.map((exercise) => {
      if (exercise._id === editedExercise._id) {
        return response.data;
      }
      return exercise;
    });
    setExercises(updatedExercises);
    setShowEditModal(false);
  })
  .catch((error) => {
    console.error('Error editing exercise:', error);
  })
  .finally(() => {
    setIsLoading(false);
  });
};

  const handleShowDeleteModal = (exercise) => {
    setDeletedExercise(exercise);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteExercise = () => {
    // Send a DELETE request to delete an exercise
    api.delete(`/exercises/${deletedExercise._id}`)
      .then(() => {
        const updatedExercises = exercises.filter((exercise) => exercise._id !== deletedExercise._id);
        setExercises(updatedExercises);
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error('Error deleting exercise:', error);
      });
  };
 

  if (isLoading) {
    // useEffect(() => {
    // fetchExercises();
    // }, []);
    fetchExercises();
  
    return  <LoadingPage/>;
  }

  return (
    <div className="container mt-4"> 
                <Card className="mb-4">
            <Card.Header> <h1>Exercise List</h1></Card.Header>
            <Card.Body>
     
      <Button className="m-2" variant="primary" onClick={handleShowAddModal}>Add Exercise</Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name (English)</th>
            <th>Name (Arabic)</th>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {exercises.map((exercise) => (
            <tr key={exercise._id}>
              <td>{exercise.name.en}</td>
              <td>{exercise.name.ar}</td>
              <td>{exercise.category && exercise.category.name}</td>
              <td>
                <Button variant="info" onClick={() => handleShowEditModal(exercise)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => handleShowDeleteModal(exercise)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for adding a new exercise */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Exercise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Exercise Name (English)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter exercise name in English"
              value={newExercise.name.en}
              onChange={(e) =>
                setNewExercise({
                  ...newExercise,
                  name: { ...newExercise.name, en: e.target.value },
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Exercise Name (Arabic)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter exercise name in Arabic"
              value={newExercise.name.ar}
              onChange={(e) =>
                setNewExercise({
                  ...newExercise,
                  name: { ...newExercise.name, ar: e.target.value },
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedCategory ? selectedCategory.name : 'Select Category'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {categories.map((category) => (
                  <Dropdown.Item
                    key={category._id}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            
          </Form.Group>
          <Form.Group>
  <Form.Label>Exercise Image</Form.Label>
  <Form.Control
    type="file"
    accept="image/*"
    onChange={(e) => setExerciseImage(e.target.files[0])}
  />
</Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>Cancel</Button>
          <Button
  variant="primary"
  onClick={handleAddExercise}
  disabled={isLoading} // Disable the button when loading
>
  {isLoading ? 'Adding...' : 'Add Exercise'}
</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing an existing exercise */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Exercise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Exercise Name (English)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Edit exercise name in English"
              value={editedExercise.name.en}
              onChange={(e) => setEditedExercise({ ...editedExercise, name: { ...editedExercise.name, en: e.target.value } })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Exercise Name (Arabic)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Edit exercise name in Arabic"
              value={editedExercise.name.ar}
              onChange={(e) => setEditedExercise({ ...editedExercise, name: { ...editedExercise.name, ar: e.target.value } })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {editedExercise.category ? editedExercise.category.name : 'Select Category'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {categories.map((category) => (
                  <Dropdown.Item
                    key={category._id}
                    onClick={() => setEditedExercise({ ...editedExercise, category })}
                  >
                    {category.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group>
  <Form.Label>Exercise Image</Form.Label>
  <Form.Control
    type="file"
    accept="image/*"
    onChange={(e) => setExerciseImage(e.target.files[0])}
  />
</Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>Cancel</Button>
          <Button variant="primary" onClick={handleEditExercise}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for confirming exercise deletion */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Exercise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deletedExercise && (
            <>
              Are you sure you want to delete the exercise: <strong>{deletedExercise.name.en} ({deletedExercise.name.ar})</strong>?
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteExercise}>Delete</Button>
        </Modal.Footer>
      </Modal>
      </Card.Body>
      </Card>
    </div>
  );
}

export default ExerciseList;
