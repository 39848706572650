import React, { createContext, useContext, useState } from 'react';

// Create a context
const AuthContext = createContext();

// Create an AuthProvider component
export const AuthProvider = ({ children }) => {
  const [accessToken, setToken] = useState(localStorage.getItem('accessToken'));

  const setAuthToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem('accessToken', newToken);
  };

  const clearAuthToken = () => {
    setToken(null);
    localStorage.removeItem('accessToken');
  };

  return (
    <AuthContext.Provider value={{ accessToken, setAuthToken, clearAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
