import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Card, InputGroup, FormControl, Modal } from 'react-bootstrap';
import api from '../../api';
import { Link } from 'react-router-dom';
import { useAuth } from '../../util/AuthContext';
import LoadingPage from '../../util/Loading';
import Paginate from '../../util/Paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faTrash, faAdd, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const { accessToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

  const [searchQuery, setSearchQuery] = useState("");
 // const [filteredUser, setFilteredUser] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    // Fetch all users from the server
    api.get('/user', {
      headers: {
        token: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        setUsers(response.data);
      //  setFilteredUser(response.data);
        setLoading(false);
      })
      .catch(error => console.error('Error fetching users:', error));
  }, [accessToken]);

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    try {
      await api.delete(`/user/${userToDelete._id}`, {
        headers: {
          token: `Bearer ${accessToken}`,
        },
      });
      // Refresh the user list after deletion
      const updatedUsers = users.filter(user => user._id !== userToDelete._id);
      setUsers(updatedUsers);
    //  setFilteredUser(updatedUsers);
      setSuccessMessage('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filterUsers = () => {
    return users.filter((user) => {
      const descriptionMatch = user?.phoneNumber?.toLowerCase().includes(searchQuery.toLowerCase());
      const idMatch = user._id.includes(searchQuery.toLowerCase());
      return descriptionMatch || idMatch;
    });
  };

  const filteredUser = filterUsers();


  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Container className='py-5'>
      <Card className="mb-4">
        <Card.Header>
          <h2>Edit List</h2>
        </Card.Header>
        <Card.Body>
          <Link to="/users/add">
            <Button variant="primary" className='m-2'>Add User  {' '}
              <FontAwesomeIcon icon={faAdd} />
            </Button>

          </Link>
          <Link to="/users/notification">
            <Button variant="warning" className='m-2'>Add Notification  {' '}
              <FontAwesomeIcon icon={faAdd} />
            </Button>

          </Link>
          <div className="d-flex align-items-center">
            <InputGroup className="mr-2 mb-2 w-25">
              <FormControl
                type="text"
                placeholder="Search About Phone Number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-25"
              />
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} style={{ color: 'blue' }} />
              </InputGroup.Text>
            </InputGroup>
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
                <th>Shop</th>
                <th>Phone Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUser.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map(user => (
                <tr key={user._id}>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.userGroup}</td>
                  <td>{user?.shopName}</td>
                  <td>{user.phoneNumber}</td>
                  <td>
                    <Link to={`/users/edit/${user._id}`}>
                      <Button variant="primary">Edit {' '}
                      <FontAwesomeIcon icon={faEdit}  />
                      </Button>
                    </Link>{' '}
                    <Button variant="danger" onClick={() => handleDeleteClick(user)}>Delete {' '}
                    <FontAwesomeIcon icon={faTrash}  />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <div className="pagination p-3">
          <Paginate
            itemsPerPage={itemsPerPage}
            totalItems={filteredUser.length}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the user with phone number: {userToDelete?.phoneNumber}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};

export default UserList;
