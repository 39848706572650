import 'bootstrap/dist/css/bootstrap.min.css';  
import { Pagination } from 'react-bootstrap';  
import React from 'react';

const Paginate = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = Math.ceil(totalItems / itemsPerPage);

  // Define the number of page links to display
  const maxPageLinks = 5;

  // Calculate the range of pages to display
  let startPage = Math.max(1, currentPage - Math.floor(maxPageLinks / 2));
  let endPage = Math.min(pageNumbers, startPage + maxPageLinks - 1);

  // Adjust the startPage if endPage is at the last page
  if (endPage === pageNumbers) {
    startPage = Math.max(1, endPage - maxPageLinks + 1);
  }

  const pageRange = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.First onClick={() => paginate(1)} />
        <Pagination.Prev
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {pageRange.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === pageNumbers}
        />
        <Pagination.Last onClick={() => paginate(pageNumbers)} />
      </Pagination>
    </div>
  );
};

export default Paginate;
