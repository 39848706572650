import React, { useState,useEffect } from 'react';
import api from '../../api';
import {Form, Button, Card } from 'react-bootstrap';

function AddPostForm({ onSubmit, postCategories }) {
  const [newImage, setNewImage] = useState(null);
  const [formData, setFormData] = useState({
  title: {
    en: '',
    ar: '',
  },
  post_content: {
    en: '',
    ar: '',
  },
  short_desc: {
    en: '',
    ar: '',
  },
  categories: '',
  image: null,
});

const handleChange = (e) => {
  const { name, value } = e.target;
  
  // Split the field name into parts based on dot notation
  const parts = name.split('.');
  
  // Create a copy of the formData object
  const updatedFormData = { ...formData };

  // Traverse the nested structure and update the value
  let current = updatedFormData;
  for (let i = 0; i < parts.length - 1; i++) {
    current = current[parts[i]];
  }
  current[parts[parts.length - 1]] = value;

  // Update the state with the modified formData
  setFormData(updatedFormData);
};

const handleSubmit = (e) => {
  e.preventDefault();

      // Create a FormData object to send both text and file data
      const AddFormData = new FormData();

      // Append text data to the FormData object
      AddFormData.append('title', JSON.stringify(formData.title));
      AddFormData.append('postcategory', formData.postcategory);
      AddFormData.append('short_desc', JSON.stringify(formData.short_desc));
      AddFormData.append('post_content', JSON.stringify(formData.post_content));
    
      // Append the image data if newImage is defined
      if (newImage) {
        AddFormData.append('img', newImage, newImage.name);
      }

  onSubmit(AddFormData);
//  console.log(AddFormData); // Check the console to verify that the 'image' property is set

};

const handleImageChange = (e) => {
  setNewImage(e.target.files[0]);
};



return (
  <Card className="mb-4">
  <Card.Header>  <h1>Add Post</h1></Card.Header>
  <Card.Body>
  <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Form.Group>
          <Form.Label>English Name</Form.Label>
          <Form.Control
            type="text"
            name="title.en"
            value={formData.title.en}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Arabic Name</Form.Label>
          <Form.Control
            type="text"
            name="title.ar"
            value={formData.title.ar}
            onChange={handleChange}
          />
        </Form.Group>
        <div>
          <label>Food Category</label>
          <Form.Select
            name="postcategory"
            value={formData.categories}
            onChange={handleChange}
          >
            <option value="">Select a category</option>
            {postCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name.en} - {category.name.ar}
              </option>
            ))}
          </Form.Select>
        </div>


        <div>
        <Form.Group>
          <Form.Label>Short Desc English</Form.Label>
          <Form.Control
            type="text"
            name="short_desc.en"
            value={formData.short_desc.en}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Short Desc Arabic</Form.Label>
          <Form.Control
            type="text"
            name="short_desc.ar"
            value={formData.short_desc.ar}
            onChange={handleChange}
          />
        </Form.Group>

     </div>
     <Form.Group>
          <Form.Label>Post Content EN</Form.Label>
          <Form.Control
           as="textarea" 
            name="post_content.en"
            value={formData.post_content.en}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Post Content Arabic</Form.Label>
          <Form.Control
           as="textarea" 
            name="post_content.ar"
            value={formData.post_content.ar}
            onChange={handleChange}
          />
        </Form.Group>
      <div>
<label>Image</label>


<Form.Control
          type="file"
          name="img"
          onChange={handleImageChange}
        />
</div>
      <Button type="submit" className="m-2">Add Post</Button>
    </Form>
  </Card.Body>
  </Card>
);
}


export default AddPostForm;
