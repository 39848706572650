import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../api';
import { useAuth } from '../../util/AuthContext';
import LoadingPage from '../../util/Loading';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
    const { accessToken } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  const navigate = useNavigate();
    const [user, setUser] = useState({
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      phoneNumber: '',
      verificationCode: '',
      isVerified: false,
      password: '',
      isAdmin: false,
      userGroup: 'user',
      status: 'not_active',
      shopName: '',
      fcmToken: '',
      // Add other fields from your model
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
  
      try {
        const response = await api.post('/user/users', user, {
          headers: {
            token: `Bearer ${accessToken}`,
          },
        });
  
        setLoading(false);
  
        // Reset the form and show a success message or redirect
        setUser({
          firstname: '',
          lastname: '',
          username: '',
          email: '',
          phoneNumber: '',
          verificationCode: '',
          isVerified: false,
          password: '',
          isAdmin: false,
          userGroup: 'user',
          status: 'not_active',
          shopName: '',
          fcmToken: '',
          // Add other fields from your model
        });
  
        alert('User created successfully:', response.data);
        navigate("/users");
      } catch (error) {
        setLoading(false);
       
        if (error.response && error.response.status === 400) {
          // Display error message to the user
          alert(error.response.data.error);
        } else {
          console.error('Error adding user:', error);
        }
      }
    };
  
    if (loading) {
      return <LoadingPage />;
    }
  


  return (
    <Container className="py-5">
      <Card className="mb-4">
        <Card.Header>
          <h2>Add User</h2>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={user.firstname}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={user.lastname}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={user.username}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Verification Code</Form.Label>
              <Form.Control
                type="text"
                name="verificationCode"
                value={user.verificationCode}
                onChange={handleChange}
              />
            </Form.Group>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Is Verified"
                    name="isVerified"
                    checked={user.isVerified}
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'isVerified', value: e.target.checked },
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Is Admin"
                    name="isAdmin"
                    checked={user.isAdmin}
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'isAdmin', value: e.target.checked },
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>User Group</Form.Label>
                  <Form.Control
                    as="select"
                    name="userGroup"
                    value={user.userGroup}
                    onChange={handleChange}
                  >
                    <option value="user">User</option>
                    <option value="wholesale">Wholesale</option>
                    <option value="wholesale+">Wholesale+</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={user.status}
                    onChange={handleChange}
                  >
                    <option value="active">Active</option>
                    <option value="not_active">Not Active</option>
                    <option value="banned">Banned</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Shop Name</Form.Label>
              <Form.Control
                type="text"
                name="shopName"
                value={user.shopName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>FCM Token</Form.Label>
              <Form.Control
                type="text"
                name="fcmToken"
                value={user.fcmToken}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Add other fields as needed */}

            <Button variant="primary" type="submit">
              Add User
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddUser;
