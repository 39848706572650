import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import api from '../../api';
import { useAuth } from '../../util/AuthContext';
import LoadingPage from '../../util/Loading';

const Home = () => {
  const [todayOrders, setTodayOrders] = useState(0);
  const [thisWeekOrders, setThisWeekOrders] = useState(0);
  const [thisMonthOrders, setThisMonthOrders] = useState(0);
  const [lastMonthOrders, setLastMonthOrders] = useState(0);
  const [numProducts, setNumProducts] = useState(0);
  const [numCategories, setNumCategories] = useState(0);
  const [numUsers, setNumUsers] = useState(0);
  const [loading, setLoading] = useState(true); // New state for loading

const {user} = useAuth
  useEffect(() => {
    const fetchData = async () => {
      try {
        const TodayOrdersResponse = await api.get('/order/order/today');
        setTodayOrders(TodayOrdersResponse.data.length);

        const thisWeekOrdersResponse = await api.get('/order/order/this-week');
        setThisWeekOrders(thisWeekOrdersResponse.data.length);

        const thisMonthOrdersResponse = await api.get('/order/order/this-month');
        setThisMonthOrders(thisMonthOrdersResponse.data.length);

        const lastMonthOrdersResponse = await api.get('/order/order/last-month');
        setLastMonthOrders(lastMonthOrdersResponse.data.length);

        const numProductsResponse = await api.get('/products/count');
        setNumProducts(numProductsResponse.data);

        const numCategoriesResponse = await api.get('/category/count');
        setNumCategories(numCategoriesResponse.data);

        const numUsersResponse = await api.get('/user/users/count');
        setNumUsers(numUsersResponse.data);
        setLoading(false); // Set loading to false after data is fetched

      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of an error

      }
    };

    fetchData();
  }, []);

if(loading) {
  return <LoadingPage/>
}

  return (
    <Container className='py-5'>
      <h2>Statistics</h2>

      <Row>
        <Col md={3}>
          <Card className="bg-primary text-white">
            <Card.Body>
              <Card.Title>Today's Orders</Card.Title>
              <Card.Text>{todayOrders}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="bg-success text-white">
            <Card.Body>
              <Card.Title>This Week's Orders</Card.Title>
              <Card.Text>{thisWeekOrders}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="bg-info text-white">
            <Card.Body>
              <Card.Title>This Month's Orders</Card.Title>
              <Card.Text>{thisMonthOrders}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="bg-warning text-dark">
            <Card.Body>
              <Card.Title>Last Month's Orders</Card.Title>
              <Card.Text>{lastMonthOrders}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={4}>
          <Card className="bg-danger text-white">
            <Card.Body>
              <Card.Title>Number of Products</Card.Title>
              <Card.Text>{numProducts}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="bg-primary text-white">
            <Card.Body>
              <Card.Title>Number of Categories</Card.Title>
              <Card.Text>{numCategories}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="bg-info text-white">
            <Card.Body>
              <Card.Title>Number of Users</Card.Title>
              <Card.Text>{numUsers}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
