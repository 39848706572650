import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PortalFooter from "./portal/footer/PortalFooter";
import PortalNavbar from "./portal/navbar/PortalNavbar";
import { AuthProvider } from "./util/AuthContext";
import { jwtDecode } from "jwt-decode";
import api from "./api";

const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // Convert to seconds

  return decodedToken.exp < currentTime;
};

const logout = () => {
  localStorage.removeItem('accessToken');
 // useNavigate('/auth/login'); // Use useNavigate directly
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const checkUserToken = async () => {
    console.log("Checking user token...");

    const userToken = localStorage.getItem("accessToken");

    if (!userToken || userToken === "undefined" || isTokenExpired(userToken)) {
      console.log("User token not present or expired.");
      localStorage.removeItem('accessToken');
      setIsLoggedIn(false);
      navigate('/auth/login');
    } else {
      try {
        const headers = {
          'token': `Bearer ${userToken}`,
        };
        const response = await api.get("/auth/checkUserStatus/", { headers });
        if (response.data.status === "active") {
          setIsLoggedIn(true);
          console.log("User is active");
        } else {
          setIsLoggedIn(false);
          console.log("User is not active");
          localStorage.removeItem('accessToken');
          navigate('/auth/login');
        }
      } catch (error) {
        console.error("Error checking user active status:", error);
        // Handle errors here
      }
    }
  };

  useEffect(() => {
    checkUserToken();

    const intervalId = setInterval(() => {
      checkUserToken();
    }, 10000); // Check every 5 seconds

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    //  console.log("Interval cleared.");
    };

  }, [navigate]);

  return (
    <AuthProvider>
      <React.Fragment>
        {isLoggedIn && <PortalNavbar />}
        <Outlet />
        {isLoggedIn && <PortalFooter />}
      </React.Fragment>
    </AuthProvider>
  );
}

export default App;
