import React from "react";
import { Button, Nav } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from "react-router-dom";

const PortalNavbar = () => {

    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/auth/login');
    }
    const Users = () => {

        navigate('/users');
    }


    const products = () => {

        navigate('/products');
    }

    const category = () => {

        navigate('/category');
    }
    const sliders = () => {

        navigate('/slider');
    }
    const exercisecategory = () => {

        navigate('/exercise-category');
    }
    const PostCategories = () => {

        navigate('/posts-categories');
    }
    const orders = () => {

        navigate('/orders');
    }
    return (
        <React.Fragment>
            <Navbar bg="dark" expand="lg" className="navbar-dark">
                <Container>
                    <Navbar.Brand>Pooboo | Portal</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">

                            <div className="m-1">
                                <Button variant="primary" className="primary" onClick={Users}>Users</Button>
                            </div>
                            <div className="m-1">
                                <Button variant="warning" className="primary" onClick={products}>Products</Button>
                            </div>
                            <div className="m-1">

                                <Button variant="info" onClick={sliders}>
                                    Slider
                                </Button>
                            </div>
                            <div className="m-1">

                                <Button variant="primary" onClick={category}>
                                    Categories
                                </Button>
                            </div>

                            <div className="m-1">

                                <Button variant="warning" onClick={orders}>
                                    Orders
                                </Button>
                            </div>
                            <div className="m-1">
                                <Button className="btn-danger" onClick={logout}>Logout</Button>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
}

export default PortalNavbar;