import React, { useState } from 'react';

function EditFoodForm({ food, onSubmit, foodCategories }) {
  // Initialize with an empty object if food is null or undefined
  const initialFormData = food || {
    name: {
      en: '',
      ar: '',
    },
    foodcategory: '',
    calories: 0,
    fat: 0,
    protein: 0,
    carb: 0,
    ingredients: {
      en: '',
      ar: '',
    },
    directions: {
      en: '',
      ar: '',
    },
    image: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [newImage, setNewImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Split the field name into parts based on dot notation
    const parts = name.split('.');

    // Create a copy of the formData object
    const updatedFormData = { ...formData };

    // Traverse the nested structure and update the value
    let current = updatedFormData;
    for (let i = 0; i < parts.length - 1; i++) {
      current = current[parts[i]];
    }
    current[parts[parts.length - 1]] = value;

    // Update the state with the modified formData
    setFormData(updatedFormData);
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };

  const clearImage = () => {
    setNewImage(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Create a FormData object to send both text and file data
    const updatedFormData = new FormData();
  
    // Append text data to the FormData object
    updatedFormData.append('name', JSON.stringify(formData.name));
    updatedFormData.append('foodcategory', formData.foodcategory);
    updatedFormData.append('calories', formData.calories);
    updatedFormData.append('fat', formData.fat);
    updatedFormData.append('protein', formData.protein);
    updatedFormData.append('carb', formData.carb);
    updatedFormData.append('ingredients', JSON.stringify(formData.ingredients));
    updatedFormData.append('directions', JSON.stringify(formData.directions));
  
    // Append the image data if newImage is defined
    if (newImage) {
      updatedFormData.append('image', newImage, newImage.name);
    }
  
    // Call the onSubmit function with the updated FormData
    onSubmit(updatedFormData);
  };
  


  return (
    <div>
      <h2>Edit Food</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
          <label>English Name</label>
          <input
            type="text"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Arabic Name</label>
          <input
            type="text"
            name="name.ar"
            value={formData.name.ar}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Food Category</label>
          <input
            type="text"
            name="foodcategory"
            value={formData.foodcategory}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Food Category</label>
          <select  
            name="foodcategory"
            value={formData.foodcategory}
            onChange={handleChange}
          >
            <option value="">Select a category</option>
            {foodCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name.en} - {category.name.ar} 
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Calories</label>
          <input
            type="number"
            name="calories"
            value={formData.calories}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Fat (g)</label>
          <input
            type="number"
            name="fat"
            value={formData.fat}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Protein (g)</label>
          <input
            type="number"
            name="protein"
            value={formData.protein}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Carbohydrates (g)</label>
          <input
            type="number"
            name="carb"
            value={formData.carb}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>English Ingredients</label>
          <input
            type="text"
            name="ingredients.en"
            value={formData.ingredients.en}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Arabic Ingredients</label>
          <input
            type="text"
            name="ingredients.ar"
            value={formData.ingredients.ar}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>English Directions</label>
          <input
            type="text"
            name="directions.en"
            value={formData.directions.en}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Arabic Directions</label>
          <input
            type="text"
            name="directions.ar"
            value={formData.directions.ar}
            onChange={handleChange}
          />
        </div>
        {/* Add an input field for updating the image if needed */}
        <div>
          <label>Current Image</label>
          {formData.image && <img src={formData.image} alt="Current Food" />}
        </div>
        <div>
          <label>Update Image</label>
          <input
            type="file"
            name="image"
            onChange={handleImageChange}
          />
        </div>
        <div>
          {newImage && (
            <div>
              <label>New Image Preview</label>
              <img src={URL.createObjectURL(newImage)} alt="New Food" />
              <button onClick={clearImage}>Remove New Image</button>
            </div>
          )}
        </div>
        <button type="submit">Update Food</button>
      </form>
    </div>
  );
}

export default EditFoodForm;
