import React, { useEffect, useState } from 'react';
import api from '../../api'; // Import your API instance
import { Table, Button, Modal, Form, Card } from 'react-bootstrap'; // Import React Bootstrap components

function CategoryList() {
    const [categories, setCategories] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedCategory, setEditedCategory] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletedCategory, setDeletedCategory] = useState({});
  
  useEffect(() => {
   


    // Fetch categories from your backend API
    api.get('exerciseCategory/exercise-categories')
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);


  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleAddCategory = () => {
    // Send a POST request to create a new category
    api.post('/exerciseCategory/exercise-categories', { name: newCategoryName })
      .then((response) => {
        setCategories([...categories, response.data]); // Add the new category to the list
        setNewCategoryName('');
        setShowAddModal(false);
      })
      .catch((error) => {
        console.error('Error adding category:', error);
      });
  };

  const handleShowEditModal = (category) => {
    setEditedCategory(category);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEditCategory = () => {
    // Send a PUT request to edit an existing category
    api.put(`/exerciseCategory/exercise-categories/${editedCategory._id}`, { name: editedCategory.name })
      .then((response) => {
        const updatedCategories = categories.map((category) => {
          if (category._id === editedCategory._id) {
            return response.data;
          }
          return category;
        });
        setCategories(updatedCategories);
        setShowEditModal(false);
      })
      .catch((error) => {
        console.error('Error editing category:', error);
      });
  };

  const handleShowDeleteModal = (category) => {
    setDeletedCategory(category);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteCategory = () => {
    // Send a DELETE request to delete a category
    api.delete(`/exerciseCategory/exercise-categories/${deletedCategory._id}`)
      .then(() => {
        const updatedCategories = categories.filter((category) => category._id !== deletedCategory._id);
        setCategories(updatedCategories);
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error('Error deleting category:', error);
      });
  };
  
  return (
    <div className="container-fluid mt-4"> 
    <Card className="mb-4">
<Card.Header>  <h1>Exercise Categories</h1></Card.Header>
<Card.Body>
     
      <Button variant="primary" onClick={handleShowAddModal}>Add Category</Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category._id}>
              <td>{category.name}</td>
              <td>
                <Button variant="info" onClick={() => handleShowEditModal(category)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => handleShowDeleteModal(category)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for adding a new category */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter category name"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>Cancel</Button>
          <Button variant="primary" onClick={handleAddCategory}>Add Category</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing an existing category */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Edit category name"
              value={editedCategory.name}
              onChange={(e) => setEditedCategory({ ...editedCategory, name: e.target.value })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>Cancel</Button>
          <Button variant="primary" onClick={handleEditCategory}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for confirming category deletion */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the category: <strong>{deletedCategory.name}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteCategory}>Delete</Button>
        </Modal.Footer>
      </Modal>
      </Card.Body>
      </Card>
    </div>
  );
}


export default CategoryList;

