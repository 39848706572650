import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import SubmissionList from './SubmissionList';
import DietForm from './DietForm';
import CourseModal from './CourseModal';
import { Button, Card, Form } from 'react-bootstrap';

function SubscriptionDetailsPage() {
  const { id } = useParams();
  const [subscription, setSubscription] = useState(null);
  const [customMealName, setCustomMealName] = useState('');
  const [showCourseForm, setShowCourseForm] = useState(false);
  const [courseIndex, setCourseIndex] = useState(-1);
  const [showCourseModal, setShowCourseModal] = useState(false);

  const [courses, setCourses] = useState([]);
  const [editingCourseIndex, setEditingCourseIndex] = useState(-1);
  const [newCourseEnglishValue, setNewCourseEnglishValue] = useState('');
  const [newCourseArabicValue, setNewCourseArabicValue] = useState('');

  useEffect(() => {
    api.get(`/sub/${id}`)
      .then(response => {
        setSubscription(response.data);
        setCourses(response.data.course);
      })
      .catch(error => {
        console.error('Error fetching subscription data:', error);
      });
  }, [id]);

  const handleUpdateCourseValues = (courseIndex, updatedValues) => {
    const updatedCourses = [...courses];
    updatedCourses[courseIndex].values = updatedValues;
    setCourses(updatedCourses);
  };

  const CourseValuesEditor = ({ values, onUpdate }) => {
    const [updatedValues, setUpdatedValues] = useState(values);

    const handleValueChange = (index, language, newValue) => {
      const updatedValue = [...updatedValues];
      updatedValue[index][language] = newValue;
      setUpdatedValues(updatedValue);
    };

    const handleUpdateSubmit = () => {
      onUpdate(updatedValues);
    };

    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>English</th>
              <th>Arabic</th>
            </tr>
          </thead>
          <tbody>
            {updatedValues.map((value, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={value.english}
                    onChange={(e) => handleValueChange(index, 'english', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={value.arabic}
                    onChange={(e) => handleValueChange(index, 'arabic', e.target.value)}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="2">
                <Button onClick={handleUpdateSubmit}>Submit</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const handleCourseDayChange = (courseIndex, newDay) => {
    const updatedCourses = [...courses];
    updatedCourses[courseIndex].day = parseInt(newDay, 10);
    setCourses(updatedCourses);
  };

  const handleDeleteCourse = (courseIndex) => {
    const updatedCourses = [...courses];
    updatedCourses.splice(courseIndex, 1);
    setCourses(updatedCourses);
  };

  const handleAddNewCourseValue = (courseIndex) => {
    const updatedCourses = [...courses];
    updatedCourses[courseIndex].values.push({
      english: '',
      arabic: '',
    });
    setCourses(updatedCourses);
    setEditingCourseIndex(courseIndex);
  };

  const handleUpdateCourse = async (courseIndex) => {
    try {
      await api.put(`/course/${id}/course`, { courses: courses });
      console.log('Course updated successfully.');
      setEditingCourseIndex(-1);
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  const handleAddNewCourse = () => {
    const newCourse = {
      day: courses.length + 1, // Auto-increment the day
      values: [],
    };
    setCourses([...courses, newCourse]);
  };




  useEffect(() => {
    api.get(`/sub/${id}`)
      .then(response => {
        setSubscription(response.data);
        setCourses(response.data.course);
      })
      .catch(error => {
        console.error('Error fetching subscription data:', error);
      });
  }, [id]);
  const renderMealValues = (meal) => {
    return meal.values.map((value, index) => (
      <div key={index}>
        <h4>{value.language}</h4>
        <ul>
          {value.foods.map((food, foodIndex) => (
            <li key={foodIndex}>
              <strong>Food:</strong> {food.foodName},&nbsp;
              <strong>Portion:</strong> {food.portionSize},&nbsp;
              <strong>kcal:</strong> {food.kcal}
            </li>
          ))}
        </ul>
      </div>
    ));
  };
  const updateDiet = () => {
    // Make an API call to update the diet information
    api.put(`/diet/${subscription._id}/diet`, subscription.diet)
      .then(response => {
        console.log(subscription.diet)
        console.log('Diet updated:', response.data);
        // Optionally, you can update the local subscription state or perform other actions after successful update
      })
      .catch(error => {
        console.error('Error updating diet:', error);
        // Handle error states here
      });
  };
  const handleFoodPropertyChange = (mealIndex, languageIndex, foodIndex, property, newValue) => {
    const updatedSubscription = { ...subscription };
    const foods = updatedSubscription.diet.meals[mealIndex].values[languageIndex].foods;

    if (newValue === '') {
      // Delete the food item
      foods.splice(foodIndex, 1);
    } else {
      // Update the property value
      foods[foodIndex][property] = newValue;
    }

    setSubscription(updatedSubscription);
  };


  const handleDeleteMeal = (mealIndex) => {
    const updatedSubscription = { ...subscription };
    updatedSubscription.diet.meals.splice(mealIndex, 1);
    setSubscription(updatedSubscription);
  };

  const handleDeleteFood = (mealIndex, languageIndex, foodIndex) => {
    const updatedSubscription = { ...subscription };
    updatedSubscription.diet.meals[mealIndex].values[languageIndex].foods.splice(foodIndex, 1);
    setSubscription(updatedSubscription);
  };

  // Helper function to add a new food item under a language
  const handleAddNewFood = (mealIndex, languageIndex) => {
    const updatedSubscription = { ...subscription };
    updatedSubscription.diet.meals[mealIndex].values[languageIndex].foods.push({
      foodName: '',
      portionSize: '',
      kcal: 0
    });
    setSubscription(updatedSubscription);
  };

  const handleAddNewMeal = () => {
    const updatedSubscription = { ...subscription };
    updatedSubscription.diet.meals.push({
      name: customMealName !== '' ? customMealName : 'Custom Meal', // Check if customMealName is not empty
      values: [
        { language: 'English', foods: [{ foodName: '', portionSize: '', kcal: 0 }] },
        { language: 'Arabic', foods: [{ foodName: '', portionSize: '', kcal: 0 }] }
      ]
    });
    setSubscription(updatedSubscription);
  };

  const handleMealNameChange = (mealIndex, newName) => {
    const updatedSubscription = { ...subscription };
    updatedSubscription.diet.meals[mealIndex].name = newName;
    setSubscription(updatedSubscription);
  };
  const handleFoodNameChange = (e, mealIndex, valueIndex, foodIndex) => {
    const updatedSubscription = { ...subscription };
    const newValue = e.target.value;

    updatedSubscription.diet.meals[mealIndex].values[valueIndex].foods[foodIndex].foodName = newValue;

    setSubscription(updatedSubscription);
  };



  return (
    <div className="container mt-4">
      {subscription && (
        <>
          <h2>Subscription Details</h2>
          {/* Display subscription details here */}
          {/* ... other subscription information ... */}

          {/* View Submissions */}

          <Card className="mb-4">
            <Card.Header>Submissions</Card.Header>
            <Card.Body>
              <SubmissionList submissions={subscription.submissions} />
            </Card.Body>
          </Card>

          {/* View/Edit Diet */}
          <Card className="mb-4">
            <Card.Header>Diet Information</Card.Header>
            <Card.Body>
              {subscription.diet ? (
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Meal Name</th>
                        <th>Language</th>
                        <th>Food Name</th>
                        <th>Portion Size</th>
                        <th>kcal</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.diet.meals.map((meal, mealIndex) => (
                        <React.Fragment key={mealIndex}>
                          <tr className='table-success'>
                            <td colSpan="6">
                              <Form.Group controlId="customMealName">
                                <Form.Label><h4>{meal.name}</h4></Form.Label>
                                <Button variant="danger" size="sm" className="ms-2" onClick={() => handleDeleteMeal(mealIndex)}>
                                  Delete Meal
                                </Button>
                                <div className="col-2">
                                  <Form.Control
                                    type="text"
                                    value={meal.name}
                                    onChange={(e) => handleMealNameChange(mealIndex, e.target.value)}
                                  />
                                </div>
                              </Form.Group>
                            </td>
                          </tr>
                          {meal.values.map((value, languageIndex) => (
                            <React.Fragment key={languageIndex}>
                              {value.foods.map((food, foodIndex) => (
                                <tr key={`${mealIndex}-${languageIndex}-${foodIndex}`} className={value.language === 'Arabic' ? 'table-secondary' : 'table-primary'}
                                >
                                  <td></td>
                                  <td>{value.language}</td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      value={food.foodName}
                                      onChange={(e) =>
                                        handleFoodPropertyChange(mealIndex, languageIndex, foodIndex, 'foodName', e.target.value)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      value={food.portionSize}
                                      onChange={(e) =>
                                        handleFoodPropertyChange(mealIndex, languageIndex, foodIndex, 'portionSize', e.target.value)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      value={food.kcal}
                                      onChange={(e) =>
                                        handleFoodPropertyChange(mealIndex, languageIndex, foodIndex, 'kcal', e.target.value)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() => handleDeleteFood(mealIndex, languageIndex, foodIndex)}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan="6">
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      handleAddNewFood(mealIndex, languageIndex)
                                    }
                                  >
                                    <span className="me-2">+</span>
                                  </Button>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <Button onClick={handleAddNewMeal}>Add New Meal</Button>
                  <Button onClick={updateDiet}>Update Diet</Button>
                  {/* ... */}
                </div>
              ) : (
                <p>No diet information available</p>
              )}
            </Card.Body>
          </Card>
        </>
      )}
      {/* View/Edit Courses */}

      {/* Display courses */}
      {/* View/Edit Courses */}




    </div>
  );
}

export default SubscriptionDetailsPage;
