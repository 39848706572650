import React from 'react';
import { Carousel,Row,Col,Card } from 'react-bootstrap';

function SubmissionList({ submissions }) {
  return (
    <div className="container mt-4">
      {submissions && (
        <>
          <h2>User Information</h2>
          {submissions.map((user, index) => (
            <Row key={index} className="mb-4">
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Card.Title>{user.name}</Card.Title>
                    <Card.Text>
                      <strong>Height:</strong> {user.height} cm<br />
                      <strong>Weight:</strong> {user.weight} kg<br />
                      <strong>Gym:</strong> {user.gym}<br />
                      <strong>Note:</strong> {user.note}<br />
                      <strong>Health:</strong> {user.health}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12} className="mt-4">
                <Carousel>
                  {user.images.map((image, imageIndex) => (
                    <Carousel.Item key={imageIndex}>
                      <img
                        className="d-block w-50"
                        src={`http://192.168.56.1:5000/upload/${image}`}
                        alt={`Image ${imageIndex + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          ))}
        </>
      )}
    </div>
  );
}


export default SubmissionList;
