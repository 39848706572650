import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

const EditProductForm = ({ product, productCategories, onSubmit }) => {
  const [editedProduct, setEditedProduct] = useState({
    name: '',
    description: '',
    categoryId: '',
  });

  useEffect(() => {
    // Set initial values when the product prop changes
    if (product) {
      setEditedProduct({
        name: product.name || '',
        description: product.description || '',
        categoryId: product?.category?._id || '',
      });
      console.log(product)
    }
  }, [product]);

  const handleChange = (e) => {
    setEditedProduct({
      ...editedProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(editedProduct);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="productName">
        <Form.Label>Product Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter product name"
          name="name"
          value={editedProduct.name}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="productDescription">
        <Form.Label>Product Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter product description"
          name="description"
          value={editedProduct.description}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Control
  as="select"
  name="categoryId"
  value={editedProduct.categoryId}
  onChange={handleChange}
>
  <option value="">Select category</option>
  {productCategories.map((category) => (
    <option
      key={category._id}
      value={category._id}
    >
      {category.name}
    </option>
  ))}
</Form.Control>



      <Button variant="primary" type="submit">
        Save Changes
      </Button>
    </Form>
  );
};

export default EditProductForm;
