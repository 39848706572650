import React, { useState } from 'react';
import { Container, Form, Card, Button } from 'react-bootstrap';
import api from '../../api';

const NotificationForm = () => {
  const [role, setRole] = useState('');
  const [notificationData, setNotificationData] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const sendNotification = async () => {
    try {
      await api.post('/user/send-notification', {
        role,
        notificationData,
      });
      console.log('Notification sent successfully');
      setSuccessMessage('Notification Sent successfully');

    } catch (error) {
      console.error('Error sending notification:', error);      
      setSuccessMessage('error Sent notification');

    }
  };

  return (
    <Container className="py-5">
                      {successMessage && (
    <div className="alert alert-success mt-3" role="alert">
        {successMessage}
    </div>
)}
      <Card className="mb-4">
        <Card.Header>
          <h2>Send Notification :</h2>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="formRole">
              <Form.Label>Role:</Form.Label>
              <Form.Control
                as="select"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
                isInvalid={role === ''}
              >
                <option value="">Select Role</option>
                <option value="all">All</option>
                <option value="user">User</option>
                <option value="wholesale">Wholesale</option>
                <option value="wholesale+">Wholesale+</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a role.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formNotificationTitle">
              <Form.Label>Notification Title:</Form.Label>
              <Form.Control
                type="text"
                value={notificationTitle}
                onChange={(e) => setNotificationTitle(e.target.value)}
                required
                isInvalid={notificationTitle === ''}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a notification title.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formNotificationData">
              <Form.Label>Notification Data:</Form.Label>
              <Form.Control
                type="text"
                value={notificationData}
                onChange={(e) => setNotificationData(e.target.value)}
                required
                isInvalid={notificationData === ''}
              />
              <Form.Control.Feedback type="invalid">
                Please provide notification data.
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" className="mt-3" onClick={sendNotification}>
              Send Notification
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NotificationForm;
